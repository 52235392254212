import { Auth } from "aws-amplify";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NumericFormat } from "react-number-format";
import CommonApi from "../../../api/CommonApi";
import {
  awsUrl,
  awsUrl2,
  clientId,
  clientIdInternal,
  pibitUrl,
} from "../../../config";
import NewProgress from "../../../newcomponent/common/NewProgress";
import UploadAcordFile from "../../../newcomponent/common/UploadAcordFile";
import UploadLossRun from "../../../newcomponent/common/UploadLossRun";
import carrierListData from "../../../utils/carrierList.json";
import { checkMailCampaignUser } from "../../../utils/check_mail_campaign_user";
import {
  getEmodStatesData,
  setStateAndCity,
  setStateAndCity1,
  setStateAndCity2,
  stepOneGet,
} from "../../../utils/form_compPro";
import { getUserType } from "../../../utils/getUserType";
import { companyProfileAddQuestions } from "../../../utils/ques_list";
import ReactMultiSelect from "../../common/ReactMultiSelect/ReactMultiSelect.jsx";
import CompLoc from "./CompLoc";
import EmodQuestions from "./EmodQuestions";

import Popup from "../../common/Popup";

var stateCity = require("../../../utils/state_city.json");
const uuidv4 = require("uuid/v4");

const { carrierPeoProgramList, demoNameMapping } = carrierListData;
var prospect, addressCount, loggedin;
class FormCompPro extends Component {
  constructor(props) {
    super(props);

    this.expectedStartDateRef = React.createRef();
    this.state = {
      username: "",
      role: "",
      selDateBus: null,
      selDateEff: moment(),
      selDateExp: moment().add(1, "year"),
      header: {},
      childrenLoc: {},
      uploadingFile: false,
      awaitingFile: false,
      error: false,
      errorMsg: "",
      urlSuccess: false,
      urlFail: false,
      basicInfoFail: false,
      stateInfoFail: false,
      signedUrl: "",
      showCompanyProfile: true,
      showLocationAndPayroll: true,
      showAdditionalQuestions: true,
      companyProfileAddQuestions: companyProfileAddQuestions,
      state_class_code: {},
      companyProfile: {
        companyName: {},
        descriptionOfOperations: {},
        firstName: {},
        lastName: {},
        yearsInBusiness: {},
        phoneNumber: {},
        entityType: {},
        expectedStartDate: { value: moment() },
        effectiveDate: { value: moment() },
        expectedExpiryDate: { value: moment().add(1, "year") },
        fein: {},
        street1: {},

        zipCode1: {},
        cityName1: {},
        state1: {},
        street2: {},
        zipCode2: {},
        cityName2: {},
        state2: {},
        producer: { value: "Paul Hughes" },
      },
      requestedPricing: {
        checked: true,
        adminPercent: "",
        perCKdollar: "",
      },
      sameAsPrimary: false,
      emodQuestions: {},
      emodStatesData: {},
      otpVerified:
        sessionStorage.getItem("otpVerified") === "true" ? true : false,
      continueErr: "",
      userDetails: {},
      peoDetails: { selectedPeo: "" },
      carrierList: { value: [], error: false },
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        rejectBtnCallback: () => {},
        show: false,
      },
    };
    this.setStateAndCity = setStateAndCity.bind(this);
    this.setStateAndCity1 = setStateAndCity1.bind(this);
    this.setStateAndCity2 = setStateAndCity2.bind(this);
    fileData: {
    }
  }

  setContinueMsg = () => {
    this.setState({
      continueErr: "",
    });
  };
  setUWQues = (quesData) => {
    let { companyProfileAddQuestions } = this.state;
    for (let quesIndex in companyProfileAddQuestions) {
      if (quesData[quesIndex].resp === "Y") {
        companyProfileAddQuestions[quesIndex].response = true;
      } else {
        companyProfileAddQuestions[quesIndex].response = false;
      }
      companyProfileAddQuestions[quesIndex].remarks =
        quesData[quesIndex].remarks;
    }
    this.setState({ companyProfileAddQuestions });
  };

  findCarriers = (peoValue, expectedStartDate) => {
    let carrierList = [];
    for (let data of carrierPeoProgramList) {
      if (data.devRemoved && process.env.REACT_APP_MODE === "dev") continue;
      if (data.prodRemoved && process.env.REACT_APP_MODE === "prod") continue;

      if (data.disableCarrier) continue;

      // if (
      //   data.displayPeoName === "LIB" &&
      //   data.actualCarrierName.indexOf("HEMIC") > -1
      // )
      //   continue;

      //includeCarrier check is only if the range is there, if no range then includeCarrier should be true.
      if (data.range) {
        expectedStartDate = moment(expectedStartDate, "YYYY-MM-DD");
        let includeCarrier = false;
        for (let range of data.range) {
          if (!range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");

            if (fromDate.isSameOrBefore(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (!range.from && range.to) {
            const toDate = moment(range.to, "YYYY-MM-DD");
            if (toDate.isAfter(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");
            const toDate = moment(range.to, "YYYY-MM-DD");

            if (
              expectedStartDate.isSameOrAfter(fromDate) &&
              expectedStartDate.isBefore(toDate)
            ) {
              includeCarrier = true;
              break;
            }
          }
        }
        if (!includeCarrier) continue;
      }

      if (
        data.peo === peoValue ||
        (peoValue === "demo" && data.peo === "gms")
      ) {
        carrierList.push({
          label: data.actualCarrierName,
          value: data.carrier,
        });
      }
    }
    return carrierList;
  };

  updateQuesList = (e, key, value) => {
    let { companyProfileAddQuestions } = this.state;
    let index = e.target.id.split("-")[1];
    companyProfileAddQuestions[index][key] = value;
    this.setState({ companyProfileAddQuestions });
  };

  addYearToDate = (dt) => {
    dt.exp = Object.assign({}, dt);
    dt.exp.eff.add(1, "year");
    return dt;
  };

  handleDateChangeBus = (date) => {
    this.setState({
      selDateBus: date,
    });
  };

  updatePopUpData = (popupData) => {
    this.setState({ popupData });
  };

  isExpectedStartDateEarlier(date) {
    const now = new Date();

    const currentDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const expectedStartDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    return expectedStartDate < currentDate;
  }

  setExpectedStartDate = (date) => {
    let { companyProfile, peoDetails } = this.state;
    let selectedPeoValue = peoDetails.selectedPeo;
    let _carrierList = [];
    _carrierList = this.findCarriers(selectedPeoValue, date);
    if (date) {
      companyProfile.expectedStartDate.value = date;
      companyProfile.effectiveDate.value = date;
      companyProfile.expectedExpiryDate = {
        value: moment(date).add(1, "year"),
      };
      this.setState({
        companyProfile,
        carrierList: { error: false, value: _carrierList },
      });
    }
  };

  handleDateChangeEff = (date) => {
    if (this.isExpectedStartDateEarlier(date)) {
      const self = this;
      self.updatePopUpData({
        show: true,
        title: "",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              The selected date is earlier than today. Do you want to continue
              with this date or change it?
            </span>
          </>
        ),
        acceptBtn: "Continue",
        acceptBtnCallback: async () => {
          /** "this" instance changes here, hence using self variable */
          self.setExpectedStartDate(date);
        },
        disableNoButton: false,
        rejectBtn: "Change",
        rejectBtnCallback: () => {
          /** "this" instance changes here, hence using self variable */
          let { companyProfile } = self.state;
          companyProfile.effectiveDate.value = null;
          companyProfile.expectedStartDate.value = null;

          self.setState({
            companyProfile,
          });

          self.expectedStartDateRef.current.setFocus();
        },
      });
    } else this.setExpectedStartDate(date);
  };

  // handleDateChangeExpEff = (date, type) => {
  //   let { companyProfile } = this.state;
  //   const { expectedExpiryDate, expectedStartDate } = companyProfile;

  //   const currentStartDate = moment(expectedStartDate.value);
  //   const selectedDate = moment(date);

  //   let tempExpiryDate = this.state.companyProfile.expectedExpiryDate;
  //   console.log(selectedDate, "in handle func");
  // this.setState({
  //   companyProfile: {
  //     ...companyProfile,
  //     expectedExpiryDate: {
  //       ...expectedExpiryDate,
  //       value: moment(date),
  //     },
  //   },
  // });
  //   // if (currentStartDate.isSameOrAfter(selectedDate)) {
  //   //   alert("Expected Expiry Date cannot be before Expected Start Date");
  //   //   this.setState({
  //   //     companyProfile: {
  //   //       ...companyProfile,
  //   //       tempExpiryDate,
  //   //     },
  //   //   });
  //   //   return;
  //   // }
  // };

  handleDateChangeExp = (date) => {
    this.setState({
      selDateExp: date,
    });
  };

  updateFormFields = (data) => {
    let { companyProfile } = this.state;
    this.setState({
      companyProfile: { ...companyProfile, ...data.companyProfile },
      childrenLoc: data.childrenLoc,
    });
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    stepOneGet();
    let dupChildrenLoc = {};

    for (let i = 0; i < 1; i++) {
      let rand = Math.random();
      dupChildrenLoc[i] = {
        number: i,
        key: rand,
        classCodesInfo: { 0: {} },
        state: {},
        zipCode: {},
        cityName: {},
        street: {},
        suta: {},
      };
    }
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
    $("#loader").css("display", "block");
  }

  // componentDidUpdate() {
  //   const { childrenLoc } = this.state;
  //   clearDelete(childrenLoc, "addressBlock");
  //   if (prospect && addressCount && loggedin) {
  //     setProspectDetails();
  //     setAddressDetails();
  //   }
  // }

  setInputData = (currProspect) => {
    let {
      childrenLoc,
      companyProfile,
      emodStatesData,
      requestedPricing,
      companyProfileAddQuestions,
      peoDetails,
      carrierList,
    } = currProspect;

    try {
      let _peoDetails = JSON.parse(JSON.stringify(peoDetails));
      let _carrierList = JSON.parse(JSON.stringify(carrierList));

      this.refreshEmodDetails(
        _peoDetails.selectedPeo === "insurecomp" ||
          peoDetails.selectedPeo === "wcn",
        emodStatesData,
        childrenLoc,
        _peoDetails.selectedPeo,
        _carrierList
      );
    } catch (error) {}

    this.setState({ peoDetails: peoDetails || { selectedPeo: "" } }, () => {
      try {
        companyProfile.expectedStartDate.value = moment(
          companyProfile.expectedStartDate.value
        );
        companyProfile.effectiveDate.value = moment(
          companyProfile.effectiveDate.value
        );
      } catch (error) {
        console.log("eeee", error);
      }

      if (companyProfile?.fein?.value) {
        var value = companyProfile?.fein?.value.toString().trim();
        value = value.split("-").join("");
        var len = value.length,
          dummystr = "000000000";

        if (isNaN(value)) {
          companyProfile.fein.value = "";
        } else if (len > 0 && len < 9) {
          value = dummystr.slice(0, 9 - len) + "" + value;
          companyProfile.fein.value =
            value[0] + value[1] + "-" + value.slice(2);
          companyProfile.fein.errMsg = "";
        } else if (len > 9) {
          companyProfile.fein.errMsg = "FEIN needs to be 9 digits";
          companyProfile.fein.error = true;
        } else if (len === 0) {
          companyProfile.fein.value = undefined;
          companyProfile.fein.errMsg = "";
        } else {
          companyProfile.fein.value =
            value[0] + value[1] + "-" + value.slice(2);
          companyProfile.fein.errMsg = "";
        }
      }

      if (companyProfile?.phoneNumber?.value) {
        var value = companyProfile?.phoneNumber?.value.toString().trim();
        if (companyProfile?.phoneNumber?.value === "") {
          return " ";
        } else {
          var numericValue = value.replace(/\D/g, "");
          companyProfile.phoneNumber.value =
            "(" +
            numericValue.slice(0, 3) +
            ") " +
            numericValue.slice(3, 6) +
            "-" +
            numericValue.slice(6);
          this.setState({ companyProfile });
        }
      }

      if (!companyProfile.producer || companyProfile.producer.value === "") {
        companyProfile.producer = {};
        companyProfile.producer.value = "Paul Hughes";
      }

      if (requestedPricing) {
        this.setState({ requestedPricing });
      }
      if (companyProfileAddQuestions) {
        for (let question of companyProfileAddQuestions) {
          if (question.response === "Y") {
            question.response = true;
          } else {
            question.response = false;
          }
        }
        this.setState({ companyProfileAddQuestions });
      }

      this.setState(
        {
          childrenLoc,
          companyProfile,
          // emodStatesData,
          carrierList: carrierList || {},
        },
        () => {
          $("#loader").css("display", "none");
        }
      );
    });
  };

  checkSameAsPrimary = () => {
    let { companyProfile } = this.state;
    let primaryKeys = ["cityName", "street", "state", "zipCode"];
    let sameAsPrimary = true;
    for (let key of primaryKeys) {
      if (companyProfile[`${key}1`].value !== companyProfile[`${key}2`].value) {
        sameAsPrimary = false;
        break;
      }
    }

    return sameAsPrimary;
  };

  componentDidMount = async () => {
    try {
      let mailCampaignUserCheck = checkMailCampaignUser();

      Auth.currentAuthenticatedUser()
        .then((userTemp) => {
          if (userTemp) {
            sessionStorage.setItem("isLoggedIn", true);
            if (
              userTemp.signInUserSession !== null &&
              userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_SALES_GROUP)
            ) {
              sessionStorage.setItem("otpVerified", true);
              this.setState({ otpVerified: true, salesLogin: true });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      const userDetails = await getUserType();
      if (userDetails?.userType === "peo" && userDetails?.peoList?.length > 0) {
        this.setState({
          peoDetails: { selectedPeo: userDetails?.peoList?.[0].value },
          userDetails,
        });
      } else {
        this.setState({ userDetails });
      }

      //check if the primary and mailing address are the same
      let formStage = sessionStorage.getItem("formStage");
      if (formStage && formStage !== "one") {
        let sameAsPrimary = this.checkSameAsPrimary();
        this.setState({ sameAsPrimary });
      }

      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let user = urlParams.get("user");
      let campaignName = urlParams.get("campaignName");
      let generalDomainNames = [
        "gmail",
        "yahoo",
        "aol",
        "mac",
        "cox",
        "frontier",
        "msn",
        "gammana",
        "hotmail",
        "pacbell",
        "att",
        "sbcglobal",
        "windstream",
        "verizon",
        "comcast",
        "ampcocontracting",
      ];
      let domain;
      let viewButton = urlParams.get("viewButton");
      if (user && !mailCampaignUserCheck) {
        $("#loader").css("display", "block");
        user = user.toLowerCase();
        let tempBusinessName = user.split("@")[1].split(".");
        tempBusinessName.pop();
        let business_name = tempBusinessName.join(".");
        if (generalDomainNames.includes(business_name)) {
          domain = user.split("@")[0];
        } else {
          domain = business_name;
        }
        let currDomain = sessionStorage.getItem("domain");
        let timestamp = Math.floor(Date.now()).toString();
        sessionStorage.setItem("visitTimestamp", timestamp);
        sessionStorage.setItem("landingEmail", user);
        axios
          .get(awsUrl + "/api/getUserDataStatus/" + user + "/quote")
          .then(async (response) => {
            let requestBody = {};
            let domainResp = await axios.get(
              awsUrl2 + "/api/getDomain/" + user
            );
            if (domainResp.data && domainResp.data.domain) {
              domain = domainResp.data.domain;
            }
            if (response.data) {
              sessionStorage.setItem("emailCampaignUser", true);
              let carrierBasedData = response.data.carrierBasedData;
              let currProspect = {},
                sortKeyList = [],
                quoteData = { uuid: "", date: "" },
                address = [];
              for (let carrier in carrierBasedData) {
                for (let line in carrierBasedData[carrier]) {
                  let lineObj = carrierBasedData[carrier][line];
                  let childrenLoc = {};

                  let uwQues = lineObj.uw_ques;

                  let companyProfile = {
                    companyName: { value: lineObj.company_name },
                    primaryContact: {
                      value: lineObj.contact_person,
                    },
                    emailId: { value: lineObj.user_email_id },
                    fein: { value: lineObj.fein },
                    phoneNumber: { value: lineObj.phone_number },
                    primaryContact: {
                      value: lineObj.contact_person,
                    },
                    effectiveDate: {
                      value: lineObj.effective_date,
                    },
                    healthQues: {
                      value:
                        lineObj.healthQues === true ||
                        lineObj.healthQues === false
                          ? lineObj.healthQues
                          : true,
                    },
                  };

                  let indicationCost = lineObj.carrier_location_data.total;

                  let emodStatesData = lineObj.emodStatesData;

                  sortKeyList.push(lineObj.uuid_carrier);
                  quoteData.date = lineObj.date;
                  quoteData.uuid = lineObj.uuid_carrier;
                  address.push(lineObj.uuid_carrier.split("+")[1]);

                  for (let prospectAddressesIndx in lineObj.prospect_addresses) {
                    let prospectAddressesObj =
                      lineObj.prospect_addresses[prospectAddressesIndx];

                    let rand = Math.random();
                    childrenLoc[prospectAddressesIndx] = {
                      cityName: {},
                      classCodesInfo: {},
                      key: rand,
                      number: prospectAddressesObj.addressId,
                      state: { value: prospectAddressesObj.stateCode },
                      zipcode: {},
                    };
                    for (let classCodesIndx in prospectAddressesObj.classCodes) {
                      var formatter = new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      });
                      let classCodesObj =
                        prospectAddressesObj.classCodes[classCodesIndx];
                      childrenLoc[prospectAddressesIndx]["classCodesInfo"][
                        classCodesIndx
                      ] = {
                        classCodeDescription: {
                          value: `${classCodesObj.code}: ${classCodesObj.description}`,
                        },
                        ft: { value: classCodesObj.ft },
                        pt: { value: classCodesObj.pt },
                        payroll: {
                          value: formatter.format(classCodesObj.payroll),
                        },
                      };
                    }
                  }
                  currProspect = {
                    childrenLoc,
                    companyProfile,
                    uwQues,
                    emodStatesData,
                  };
                  sessionStorage.setItem(
                    "currProspect",
                    JSON.stringify(currProspect)
                  );
                  sessionStorage.setItem(
                    "childrenLoc",
                    JSON.stringify(childrenLoc)
                  );
                  sessionStorage.setItem(
                    "sortKeyList",
                    JSON.stringify(sortKeyList)
                  );
                  sessionStorage.setItem(
                    "quoteData",
                    JSON.stringify(quoteData)
                  );
                  sessionStorage.setItem("address", JSON.stringify(address));
                  sessionStorage.setItem("formStage", "three");
                  sessionStorage.setItem("domain", domain);
                  requestBody = {
                    email: user.toLowerCase(),
                    domain: domain.toLowerCase(),
                    timestamp: timestamp,
                    payrollData: childrenLoc,
                    companyProfile,
                    indicationCost,
                    emodStatesData,
                    campaignName,
                    preventCompanyProfileUpdate: true,
                  };

                  if (user && !viewButton) {
                    axios
                      .post(
                        awsUrl2 + "/api/updateSolveTrackingData",
                        JSON.stringify(requestBody)
                      )
                      .then((res) => {
                        axios
                          .post(
                            awsUrl2 +
                              "/api/updateEngagedWithEmailCampaign/" +
                              domain,
                            JSON.stringify(domain)
                          )
                          .finally(() => {
                            $("#loader").css("display", "none");
                            window.location.reload();
                          });
                      })
                      .catch((err) => {
                        $("#loader").css("display", "none");
                      });
                  } else {
                    $("#loader").css("display", "none");
                    window.location.reload();
                  }
                }
              }
            }
          })
          .catch((err) => {
            $("#loader").css("display", "none");
          });
      } else if (sessionStorage.getItem("currProspect")) {
        let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
        this.setInputData(currProspect);
      } else {
        $("#loader").css("display", "none");
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  autoFillAcordData = (data, that) => {
    console.log("data", data);
    sessionStorage.setItem("acordData", JSON.stringify(data));
    const { basic_information, state_details, location_details } = data;
    if (
      basic_information.eff_date &&
      moment(basic_information.eff_date).isValid()
    ) {
      this.setState({ selDateEff: moment(basic_information.eff_date) });
    }
    if (
      basic_information.exp_date &&
      moment(basic_information.exp_date).isValid()
    ) {
      this.setState({ selDateExp: moment(basic_information.exp_date) });
    }
    if (basic_information.fein) {
      this.setState({ fein: basic_information.fein });
    }
    if (basic_information.company_name) {
      this.setState({ company_name: basic_information.company_name });
    }
    if (basic_information.phone_number) {
      this.setState({ phone_number: basic_information.phone_number });
    }

    if (basic_information.desc) {
      $("#description").val(basic_information.desc);
    }

    if (Object.entries(location_details).length > 0) {
      for (let locNumber in location_details) {
        that.onAddChildLoc(location_details[locNumber]);
      }
      that.onrmvChild(0);
    }
  };

  updateCompanyProfile = (event, fieldName) => {
    let { companyProfile } = this.state;
    if (fieldName === "state1" || fieldName === "state2") {
      companyProfile[fieldName] = { value: event.target.value.toLowerCase() };
    } else {
      companyProfile[fieldName] = { value: event.target.value };
    }
    this.setState({ companyProfile });
  };

  refreshEmodDetails = (
    isLibPeoSelected,
    emodStatesData,
    childrenLoc,
    selectedPeo,
    selectedCarrList
  ) => {
    try {
      let stateList = [],
        stateSet = {};
      for (let addressBlockNo in childrenLoc) {
        if (childrenLoc[addressBlockNo].state.value) {
          stateList.push(childrenLoc[addressBlockNo].state.value.toLowerCase());
          stateSet[childrenLoc[addressBlockNo].state.value] =
            childrenLoc[addressBlockNo].state.value;
        }
      }
      if (stateList.length > 0) {
        sessionStorage.setItem("stateSet", JSON.stringify(stateSet));

        if (isLibPeoSelected) {
          let statesData = {
            ncciStates: [],
            actingNcciStates: [],
            indBureauStates: [],
          };
          axios
            .post(
              awsUrl2 + "/api/getExpModStateCategory",
              JSON.stringify({ states: stateList })
            )
            .then((response) => {
              statesData = response.data;
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              // console.log(JSON.parse(JSON.stringify(emodStatesData)));
              let newEmodData = getEmodStatesData(statesData, emodStatesData);
              // console.log(JSON.parse(JSON.stringify(newEmodData)));
              this.setState({ emodStatesData: newEmodData });
            });
        } else {
          let carrierPeoProgramList = carrierListData.carrierPeoProgramList;

          let programList = [];

          let hasCarrier = (list, carr) => {
            // console.log(list);
            for (let ele of list) {
              if (ele.value === carr) return true;
            }
            return false;
          };

          for (let carrierPeoProgramObj of carrierPeoProgramList) {
            if (
              (carrierPeoProgramObj.peo === selectedPeo ||
                (selectedPeo === "demo" &&
                  carrierPeoProgramObj.peo === "gms")) &&
              hasCarrier(selectedCarrList.value, carrierPeoProgramObj.carrier)
            )
              programList.push({ display_name: carrierPeoProgramObj.program });
          }

          axios
            .post(
              awsUrl2 + "/api/raterMcpStateData",
              JSON.stringify({ stateList, programList })
            )
            .then((response) => {
              if (response?.data?.length > 0) {
                let validateEmodStateObject =
                  JSON.parse(sessionStorage.getItem("validateEmodState")) || "";
                for (let state of response.data) {
                  // console.log(JSON.parse(JSON.stringify(emodStatesData)));

                  if (!(state in emodStatesData)) {
                    let valueToUse = "";
                    if (
                      emodStatesData?.["ncci"]?.["stateList"]?.includes(state)
                    ) {
                      valueToUse = emodStatesData["ncci"]?.rate1?.value || "";
                    }
                    emodStatesData[state] = {
                      rate1: {
                        value: valueToUse,
                      },
                      response: valueToUse ? true : false,
                      displayMore: false,
                      date1: { value: moment() },
                      date2: { value: moment() },
                    };
                  }
                  if (validateEmodStateObject) {
                    let valueToUse = "";
                    emodStatesData[state] = {
                      rate1: {
                        value:
                          validateEmodStateObject?.["validateEmodState"]?.[
                            state
                          ]?.["emod"] || "",
                      },
                      response: valueToUse ? true : false,
                      displayMore: false,
                      date1: {
                        value: validateEmodStateObject?.["validateEmodState"]?.[
                          state
                        ]?.["emodDate"]
                          ? moment(
                              validateEmodStateObject?.["validateEmodState"]?.[
                                state
                              ]?.["emodDate"]
                            )
                          : moment(),
                      },
                      date2: {
                        value: validateEmodStateObject?.["validateEmodState"]?.[
                          state
                        ]?.["emodDate"]
                          ? moment(
                              validateEmodStateObject?.["validateEmodState"]?.[
                                state
                              ]?.["emodDate"]
                            )
                          : moment(),
                      },
                    };
                  }
                  // console.log(JSON.parse(JSON.stringify(emodStatesData)));
                }
                if (emodStatesData?.["ncci"]) {
                  delete emodStatesData["ncci"];
                }
                for (let state in emodStatesData) {
                  if (
                    !stateList.includes(state) ||
                    !response?.data?.includes(state)
                  ) {
                    delete emodStatesData[state];
                  }
                }
              } else {
                emodStatesData = {};
              }
              this.setState({ emodStatesData });
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      } else {
        this.setState({ emodStatesData: {} });
      }
    } catch (error) {
      console.log("Error in refresh Emod Details ", error);
    }
  };

  deleteAddress = (addressId) => {
    try {
      let { childrenLoc, emodStatesData, peoDetails, carrierList } = this.state;
      if (childrenLoc[addressId]) delete childrenLoc[addressId];

      this.refreshEmodDetails(
        peoDetails.selectedPeo === "insurecomp" ||
          peoDetails.selectedPeo === "wcn",
        emodStatesData,
        childrenLoc,
        peoDetails.selectedPeo,
        carrierList
      );

      this.setState({ childrenLoc });
    } catch (error) {
      console.log("Error deleting location: ", error);
    }
  };

  updateLocationInfo = (value, locationIndex, fieldName, classCodeIndex) => {
    let { childrenLoc, emodStatesData, peoDetails, carrierList } = this.state;
    if (classCodeIndex) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex] = {};
    } else {
      // console.log("locationIndex: ", locationIndex);
      childrenLoc[locationIndex][fieldName] = { value: value };
      if (fieldName === "state") {
        this.refreshEmodDetails(
          peoDetails.selectedPeo === "insurecomp" ||
            peoDetails.selectedPeo === "wcn",
          emodStatesData,
          childrenLoc,
          peoDetails.selectedPeo,
          carrierList
        );
      }
    }
    this.setState({ childrenLoc });
  };

  removeClassCode = (locationIndex, classCodeIndex) => {
    let { childrenLoc, state_class_code } = this.state;

    if (
      !childrenLoc[locationIndex].state ||
      !childrenLoc[locationIndex].state.value
    ) {
      delete childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
      this.setState({ childrenLoc });
      return;
    }

    //code to handle state-classcode combination requested pricing starts
    let currState = childrenLoc[locationIndex].state.value || "";
    if (
      currState.toLowerCase() in state_class_code &&
      state_class_code[currState.toLowerCase()].val
    ) {
      let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
        classCodeIndex
      ]["classCodeDescription"]
        ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
            "classCodeDescription"
          ].value
        : undefined;
      if (class_code_desc) {
        let class_code = class_code_desc.split(":")[0].trim();
        if (
          state_class_code[currState.toLowerCase()].cc_dsc &&
          class_code in state_class_code[currState.toLowerCase()].cc_dsc
        ) {
          let locToUpdate, ccToUpdate;
          for (let locIndx in childrenLoc) {
            if (childrenLoc[locIndx].state.value === currState) {
              let ccInfo = childrenLoc[locIndx].classCodesInfo;
              for (let ccIndx in ccInfo) {
                if (
                  !(
                    ccInfo[ccIndx] &&
                    ccInfo[ccIndx].classCodeDescription &&
                    ccInfo[ccIndx].classCodeDescription.value
                  )
                ) {
                  continue;
                }
                let local_cc_desc = ccInfo[ccIndx].classCodeDescription.value;

                let local_cc = local_cc_desc.split(":")[0].trim();
                if (
                  !locToUpdate &&
                  !ccToUpdate &&
                  (Number(locIndx) !== Number(locationIndex) ||
                    Number(ccIndx) !== Number(classCodeIndex)) &&
                  Number(class_code) === Number(local_cc)
                ) {
                  childrenLoc[locIndx].classCodesInfo[
                    ccIndx
                  ].pricing.disabled = true;
                  locToUpdate = locIndx;
                  ccToUpdate = ccIndx;
                }
              }
            }
          }
          if (locToUpdate && ccToUpdate) {
            childrenLoc[locToUpdate].classCodesInfo[
              ccToUpdate
            ].pricing.disabled = false;
          } else {
            delete state_class_code[currState.toLowerCase()].cc_dsc[class_code];
          }
        }
      }
    }
    //code to handle state-classcode ends here

    delete childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
    this.setState({ childrenLoc, state_class_code });
  };

  updateClassCodesInfo = (
    locationIndex,
    classCodeIndex,
    fieldName,
    value,
    deleteAcordFlag
  ) => {
    let { childrenLoc, state_class_code } = this.state;

    childrenLoc[locationIndex].classCodesInfo[classCodeIndex][fieldName] = {
      value: value,
    };

    // remove the from acord flag
    if (deleteAcordFlag) {
      delete childrenLoc[locationIndex].classCodesInfo[classCodeIndex]
        ?.fromAcord;
    }

    if (fieldName === "pricing") {
      let currState = childrenLoc[locationIndex].state.value || "";
      if (currState.toLowerCase() in state_class_code) {
        let class_code_desc;
        try {
          class_code_desc =
            childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
              "classCodeDescription"
            ].value;
        } catch (error) {}
        if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
          state_class_code[currState.toLowerCase()].cc_dsc = {};
        }
        if (class_code_desc) {
          let class_code = class_code_desc.split(":")[0].trim();
          if (
            !(class_code in state_class_code[currState.toLowerCase()].cc_dsc)
          ) {
            state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
              value;
          } else {
            let locToUpdate, ccToUpdate;
            for (let locIndx in childrenLoc) {
              if (childrenLoc[locIndx].state.value === currState) {
                let ccInfo = childrenLoc[locIndx].classCodesInfo;
                for (let ccIndx in ccInfo) {
                  let cc_desc;
                  try {
                    cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                  } catch (error) {}
                  if (cc_desc) {
                    let cc = cc_desc.split(":")[0].trim();
                    if (cc === class_code) {
                      childrenLoc[locIndx].classCodesInfo[ccIndx]["pricing"] = {
                        value: value,
                        disabled: true,
                      };
                      if (!locToUpdate && !ccToUpdate) {
                        locToUpdate = locIndx;
                        ccToUpdate = ccIndx;
                      }
                    }
                  }
                }
              }
            }
            if (locToUpdate && ccToUpdate) {
              childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                "pricing"
              ].disabled = false;

              state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                value;
            }
          }
        }
      }
    }

    if (fieldName === "classCodeDescription") {
      let currState = childrenLoc[locationIndex].state.value || "";
      if (currState.toLowerCase() in state_class_code) {
        let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
          classCodeIndex
        ]["classCodeDescription"]
          ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
              "classCodeDescription"
            ].value
          : undefined;

        if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
          state_class_code[currState.toLowerCase()].cc_dsc = {};
        }
        if (class_code_desc) {
          let class_code = class_code_desc.split(":")[0].trim();
          if (class_code in state_class_code[currState.toLowerCase()].cc_dsc) {
            let locToUpdate, ccToUpdate;
            for (let locIndx in childrenLoc) {
              if (childrenLoc[locIndx].state.value === currState) {
                let ccInfo = childrenLoc[locIndx].classCodesInfo;
                for (let ccIndx in ccInfo) {
                  let cc_desc;
                  try {
                    cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                  } catch (e) {}
                  if (cc_desc) {
                    let cc = cc_desc.split(":")[0].trim();
                    if (cc === class_code) {
                      childrenLoc[locIndx].classCodesInfo[ccIndx]["pricing"] = {
                        value:
                          state_class_code[currState.toLowerCase()].cc_dsc[
                            class_code
                          ],
                        disabled: true,
                      };
                      if (!locToUpdate && !ccToUpdate) {
                        locToUpdate = locIndx;
                        ccToUpdate = ccIndx;
                      }
                    }
                  }
                }
              }
            }
            if (locToUpdate && ccToUpdate) {
              childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                "pricing"
              ].disabled = false;
            }
          } else {
            if (
              childrenLoc[locationIndex].classCodesInfo[classCodeIndex]
                .pricing &&
              childrenLoc[locationIndex].classCodesInfo[classCodeIndex].pricing
                .value
            ) {
              state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                childrenLoc[locationIndex].classCodesInfo[
                  classCodeIndex
                ].pricing.value;
            }
          }
        }
      }
    }

    if (childrenLoc[locationIndex].classCodesInfo[classCodeIndex]) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
        "modifiedDate"
      ] = {
        value: moment().format("M/D/YYYY"),
      };
    }

    this.setState({ childrenLoc, state_class_code });
  };

  updateLocationError = (locationIndex, classCodeIndex, fieldName, value) => {
    let { childrenLoc } = this.state;
    if (
      classCodeIndex &&
      childrenLoc?.[locationIndex]?.classCodesInfo?.[classCodeIndex]?.[
        fieldName
      ]
    ) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
        fieldName
      ].error = value;
    } else if (childrenLoc?.[locationIndex]?.[fieldName]) {
      childrenLoc[locationIndex][fieldName].error = value;
    }
    this.setState({ childrenLoc });
  };

  resetClassCodeDescriptionData = (locationIndex) => {
    let { childrenLoc } = this.state;
    for (let index in childrenLoc[locationIndex].classCodesInfo) {
      if (
        childrenLoc[locationIndex].classCodesInfo[index].classCodeDescription
      ) {
        delete childrenLoc[locationIndex].classCodesInfo[index]
          .classCodeDescription.value;
      }
      if (childrenLoc[locationIndex].classCodesInfo[index].pricing) {
        delete childrenLoc[locationIndex].classCodesInfo[index].pricing;
      }
    }
    this.setState({ childrenLoc });
  };

  checkCompanyProfileError = () => {
    let { companyProfile } = this.state;

    let fieldsToCheck = [
      "companyName",
      "descriptionOfOperations",
      "firstName",
      "lastName",
      "yearsInBusiness",
      "entityType",
      "expectedStartDate",
      "effectiveDate",
      "fein",
      "producer",
    ];

    let error = false;
    let field;
    for (let fieldName of fieldsToCheck) {
      if (
        !companyProfile?.[fieldName]?.value ||
        companyProfile?.[fieldName]?.error
      ) {
        companyProfile[fieldName].error = true;
        if (!field) field = fieldName;
        error = true;
      }
      if (fieldName === "entityType") {
        if (
          companyProfile?.[fieldName]?.value === "" ||
          companyProfile?.[fieldName]?.value === undefined ||
          companyProfile?.[fieldName]?.value === "Please Select"
        ) {
          companyProfile[fieldName].error = true;
          if (!field) field = fieldName;
          error = true;
        }
      }

      // if (fieldName === "primaryContact") {
      //   var reg = /^[a-z A-Z\b]+$/;
      //   var isValid = reg.test(companyProfile[fieldName].value);
      //   if (!isValid) {
      //     companyProfile[fieldName].error = true;
      //     if (!field) field = fieldName;
      //     error = true;
      //   }
      // }
    }
    if (
      companyProfile["state1"] &&
      companyProfile["state1"]?.value?.toLowerCase() === "ca" &&
      !(companyProfile["zipCode1"] && companyProfile["zipCode1"]?.value)
    ) {
      companyProfile["zipCode1"].error = true;
      if (!field) field = "zipCode1";
      error = true;
    } else {
      companyProfile["zipCode1"].error = false;
    }
    if (
      companyProfile["state2"] &&
      companyProfile["state2"]?.value?.toLowerCase() === "ca" &&
      !(companyProfile["zipCode2"] && companyProfile["zipCode2"]?.value)
    ) {
      companyProfile["zipCode2"].error = true;
      if (!field) field = "zipCode2";
      error = true;
    } else {
      companyProfile["zipCode2"].error = false;
    }
    this.setState({ companyProfile });
    return [error, field];
  };

  checkLocationInfoError = () => {
    //akshay here
    let { childrenLoc } = this.state;
    let error = false;
    let locationKeys = ["zipCode", "state", "cityName"];
    let classCodeKeys = ["classCodeDescription", "payroll"];
    let field;
    let index = 0;
    for (let addressBlockNo in childrenLoc) {
      locationKeys.forEach((fieldName) => {
        if (childrenLoc[addressBlockNo].state.value === "CA") {
          if (
            !childrenLoc[addressBlockNo][fieldName].value ||
            childrenLoc[addressBlockNo][fieldName].error
          ) {
            childrenLoc[addressBlockNo][fieldName].error = true;
            error = true;
            if (!field) field = "officeLoc" + addressBlockNo;
          }
        } else if (fieldName !== "zipCode" && fieldName !== "cityName") {
          if (
            !childrenLoc[addressBlockNo][fieldName].value ||
            childrenLoc[addressBlockNo][fieldName].error
          ) {
            childrenLoc[addressBlockNo][fieldName].error = true;
            error = true;
            if (!field) field = "officeLoc" + addressBlockNo;
          }
        }
      });

      for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
        if (!childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]) {
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"] = {};
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "ft"
          ].error = true;
          error = true;
          if (!field) field = "officeLoc" + addressBlockNo;
        } else if (
          !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
        ) {
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"] = {};
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "pt"
          ].error = true;
          error = true;
          if (!field) field = "officeLoc" + addressBlockNo;
        } else if (
          !(
            Number(
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]
                .value
            ) ||
            Number(
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
                .value
            )
          )
        ) {
          if (
            !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]
              .value
          )
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              "ft"
            ].error = true;
          if (
            !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
              .value
          )
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              "pt"
            ].error = true;
          error = true;
          if (!field) field = "officeLoc" + addressBlockNo;
        } else {
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "ft"
          ].error = false;
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "pt"
          ].error = false;
        }

        classCodeKeys.forEach((fieldName) => {
          if (
            !(
              fieldName in
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
            )
          ) {
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ] = { error: true };
            error = true;
            if (!field) field = "officeLoc" + addressBlockNo;
          } else if (
            !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].value ||
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].error ||
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].value?.trim() === "$"
          ) {
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].error = true;
            if (!field) field = "officeLoc" + addressBlockNo;
            error = true;
          }
        });
      }
      index++;
    }
    this.setState({ childrenLoc });
    return [error, field];
  };

  checkEmodInfoError = () => {
    let { emodStatesData } = this.state;
    let error = false;
    for (let state in emodStatesData) {
      if (emodStatesData[state].response) {
        if (!emodStatesData[state].rate1) {
          error = true;
          emodStatesData[state].rate1 = { value: "", error: true };
        } else if (
          !emodStatesData[state].rate1.value ||
          isNaN(emodStatesData[state].rate1.value)
        ) {
          error = true;
          emodStatesData[state].rate1.error = true;
        }
        if (emodStatesData[state].displayMore) {
          if (!emodStatesData[state].rate2) {
            error = true;
            emodStatesData[state].rate2 = { value: "", error: true };
          } else if (
            !emodStatesData[state].rate2.value ||
            isNaN(emodStatesData[state].rate2.value)
          ) {
            error = true;
            emodStatesData[state].rate2.error = true;
          }
        }
      }
    }

    return error;
  };

  validEmail = (e) => {
    let { companyProfile, otpVerified } = this.state;
    var email = e.target.value;
    var reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = reg.test(email);
    if (!isValid && email !== "") {
      companyProfile.emailId.error = true;
    } else {
      if (!otpVerified) {
        axios
          .post(awsUrl2 + "/api/sendOtpMail", { email })
          .then((res) => {
            this.setState({
              otpMessage:
                "A 4-digit verification code has been sent to your email.",
            });
            console.log("sent OTP successfully");
          })
          .catch((error) => {
            console.log("error while sending OTP");
          });
      }
    }
    this.setState({ companyProfile });
  };

  verifyOtp(email, otp) {
    axios
      .post(awsUrl2 + "/api/verifyOtp", { email, otp })
      .then((res) => {
        if (res.data === "otp_verified") {
          this.setState({
            otpMessage: "Email verified successfully.",
            otpVerified: true,
          });
          this.fetchAndFillDetails();
        } else if (res.data === "otp_expired") {
          this.setState({
            otpMessage: "OTP expired.",
          });
        } else if (res.data === "invalid_otp") {
          this.setState({
            otpMessage: "Invalid OTP.",
          });
        } else if (res.data === "otp_not_generated") {
          this.setState({
            otpMessage: "OTP not generated.",
          });
        }
      })
      .catch((error) => {
        console.log("error while sending OTP");
        this.setState({
          otpMessage: "Unable to verify OTP.",
        });
      });
  }

  updateEmodInfo = (state, fieldName, value) => {
    let { emodStatesData } = this.state;
    let dataFieldNames = ["rate1", "rate2", "date1", "date2"];
    if (dataFieldNames.includes(fieldName)) {
      if (fieldName === "date1" || fieldName == "date2") {
        value = value ? value : moment();
      }
      emodStatesData[state][fieldName] = {
        value: value,
      };
    } else {
      emodStatesData[state][fieldName] = value;
    }

    this.setState({ emodStatesData });
  };

  createRiskProfileBody = (childrenLoc, peoDetails, companyProfile) => {
    let riskProfileBody = {
      locationData: {},
      peo: peoDetails.selectedPeo === "demo" ? "gms" : peoDetails.selectedPeo,
      effectiveDate: moment(
        companyProfile.effectiveDate.value,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD"),
    };
    let stateLocMap = {};
    for (let addressBlockNo in childrenLoc) {
      let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${
        childrenLoc[addressBlockNo].state.value
      }_${
        (childrenLoc[addressBlockNo].zipCode ||
          childrenLoc[addressBlockNo].zipcode)["value"]
      }`;

      let key = `${name}_${addressBlockNo}`;
      if (!(key in riskProfileBody.locationData)) {
        let state = key.split("_")[3].toLowerCase();
        if (state in stateLocMap) {
          key = stateLocMap[state];
        } else {
          riskProfileBody.locationData[key] = [];
          stateLocMap[state] = key;
        }
      }

      for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
        let classCodeDescArray =
          childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

        let code = classCodeDescArray.shift();
        let desc = classCodeDescArray.join(":").trim();
        let state = childrenLoc[addressBlockNo].state.value.toLowerCase();
        let payroll =
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
            .value;
        let ft =
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft.value;
        let pt =
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pt.value;

        riskProfileBody.locationData[key].push({
          code,
          description: desc,
          state,
          payroll,
          ft,
          pt,
        });
      }
    }

    return riskProfileBody;
  };

  checkPeoDetailsError = () => {
    let { peoDetails } = this.state;
    let peoDetailsCopy = { ...peoDetails };
    let response = false;

    try {
      if (peoDetailsCopy.selectedPeo) {
        response = false;
      } else {
        response = true;
      }
      peoDetailsCopy.error = response;
      this.setState({ peoDetails: peoDetailsCopy });
    } catch (error) {
      console.log("error", error);
      response = true;
    }

    return response;
  };

  checkCarrierList = () => {
    let { carrierList } = this.state;
    let _carrierList = { ...carrierList };

    let response = false;

    try {
      if (_carrierList.error || _carrierList.value.length === 0) {
        response = true;
      } else {
        response = false;
      }
      _carrierList.error = response;
      this.setState({ carrierList: _carrierList });
    } catch (error) {
      response = true;
    }

    return response;
  };
  // Amtrust createquote
  amtrustCreateQuoteResponse = (uuid) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let res = await axios.get(
          awsUrl2 + `/api/getAmtrustData/${uuid}/createQuote`
        );
        console.log(
          res.data,
          "++++++++++++++++++++++++++++++++++++++++++++++++++++++"
        );
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };

  formatPhoneNumber = (data) => {
    try {
      let phoneNumberString = data;
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      if (cleaned) {
        let len = cleaned.length;
        for (let i = 1; i <= 10 - len; i++) {
          cleaned = "0" + cleaned;
        }
        return cleaned;
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error while formatting phone number", error);
      return data;
    }
  };
  submitCompPro = async () => {
    let {
      childrenLoc,
      companyProfile,
      emodStatesData,
      requestedPricing,
      companyProfileAddQuestions,
      peoDetails,
      carrierList,
      fileData,
    } = this.state;

    let isLoggedIn = await Auth.currentAuthenticatedUser();
    // console.log("isLoggedIn: ", isLoggedIn);
    let emailID = isLoggedIn.attributes.email;
    // let mailCampaignUserCheck = checkMailCampaignUser();
    let [companyProfileError, companyfield] = this.checkCompanyProfileError();
    let [locationInfoError, locationInfoField] = this.checkLocationInfoError();
    let peoDetailsError = this.checkPeoDetailsError();
    // let emodInfoError = this.checkEmodInfoError();
    let carrierListError = this.checkCarrierList();
    let currProspect;
    let uw_flow = sessionStorage.getItem("uw_flow") ? true : false;

    if (carrierListError) {
      document
        .getElementById("peo-select-container")
        .scrollIntoView({ behavior: "smooth" });
    } else if (peoDetailsError) {
      document
        .getElementById("peo-select-container")
        .scrollIntoView({ behavior: "smooth" });
    } else if (companyProfileError) {
      document.getElementById(companyfield).focus();
    } else if (locationInfoError) {
      document
        .getElementById(locationInfoField)
        .scrollIntoView({ behavior: "smooth" });
    }

    if (
      !companyProfileError &&
      !locationInfoError &&
      !peoDetailsError &&
      !carrierListError
    ) {
      $("#loader").css("display", "block");

      let user_id = sessionStorage.getItem("user_id");

      if (!user_id) {
        let uuid = uuidv4(),
          curr_date = moment().format("YYYYMMDD");
        user_id = `user_${curr_date}_${uuid}`;
        sessionStorage.setItem("user_id", user_id);
      }
      if (!sessionStorage.getItem("uploadLossRun") && fileData?.file) {
        await this.onContinuePibitCall(fileData);
      }

      let addQuesData = [];
      for (let question of companyProfileAddQuestions) {
        let dataQues = {
          response: question.response ? "Y" : "N",
          name: question.name,
          ques: question.ques,
        };
        addQuesData.push(dataQues);
      }

      let riskProfileRequestBody = this.createRiskProfileBody(
        childrenLoc,
        peoDetails,
        companyProfile
      );
      let riskProfileResponse = {};

      await new Promise(async (resolve, reject) => {
        try {
          const riskProfileDataID = await axios.post(
            awsUrl + `/api/getRiskProfileDataId`,
            JSON.stringify({ body: riskProfileRequestBody })
          );

          let id = riskProfileDataID.data.id;

          const fetchRiskProfile = async (id) => {
            await axios
              .get(awsUrl + `/api/getRiskProfileData/${id}`)
              .then(async (res) => {
                if (res.data === "processing") {
                  setTimeout(() => fetchRiskProfile(id), 5000);
                } else {
                  let urlResponse = await axios.get(res.data.url);
                  riskProfileResponse = urlResponse.data;

                  // console.log(riskProfileResponse, res.data);
                  resolve();

                  // $("#loader").css("display", "none");
                }
              });
          };

          await fetchRiskProfile(id);
        } catch (error) {
          console.log(error);
          // $("#loader").css("display", "none");
          resolve();
        }
      });

      currProspect = {
        childrenLoc: childrenLoc,
        companyProfile: companyProfile,
        emodStatesData: emodStatesData,
        requestedPricing,
        companyProfileAddQuestions: addQuesData,
        riskProfile: riskProfileResponse,
        peoDetails,
        carrierList,
      };

      let salesforceData;
      try {
        salesforceData = JSON.parse(sessionStorage.getItem("salesforceData"));
      } catch (error) {}

      let miscSalesforceData;
      try {
        miscSalesforceData = JSON.parse(
          sessionStorage.getItem("miscSalesforceData")
        );
      } catch (error) {}

      let response;
      let req_body = { user_email_id: sessionStorage.getItem("user_id") };
      if (req_body) {
        response = await axios.post(
          awsUrl2 + "/api/userTableData/get",
          req_body
        );
      }
      let etQuoteDetails = {
        user_email_id: user_id,
        childrenLoc: childrenLoc,
        companyProfile: companyProfile,
        emodStatesData: emodStatesData,
        status: "company_profile",
        uploadTimestamp: Math.floor(Date.now()).toString(),
        modifiedBy: emailID,
        riskProfile: riskProfileResponse,
        peoDetails,
        carrierList,
        secondary_index_hash_key: "true",
        origin_instance: "ies",
        summaryData: response?.data[0]?.summaryData,
        uploadedLossRun: response?.data[0]?.lossRunDocumentUploaded
          ? response?.data[0]?.lossRunDocumentUploaded
          : response?.data[0]?.uploadedLossRun,
        // amtrustAccountDetails: amtrustQuoteResponse.Data.AccountInformation,
      };

      if (salesforceData) {
        etQuoteDetails.salesforceData = salesforceData;
      }

      if (miscSalesforceData) {
        etQuoteDetails.miscSalesforceData = miscSalesforceData;
      }

      let errorInClassCodeValidation = false,
        validationCcField = "";

      await axios
        .post(awsUrl2 + "/api/validateClassCodesAndDescription", {
          childrenLoc,
        })
        .then((res3) => {
          let responseData = res3.data;
          if (responseData.hasError) errorInClassCodeValidation = true;
          childrenLoc = responseData.childrenLoc;
          validationCcField = responseData.field;
        })
        .catch((err) => {
          console.log("Error:", err);
        });

      if (!errorInClassCodeValidation) {
        if (uw_flow) {
          try {
            let _currProspect = JSON.parse(
              sessionStorage.getItem("currProspect")
            );
            let _uwQues = _currProspect.uwQues;
            currProspect.uwQues = _uwQues;
          } catch (error) {}
        } else {
          await axios
            .post(awsUrl2 + "/api/userTableData/put", etQuoteDetails)
            .then((res2) => {})
            .catch((err) => {
              console.log("Error:", err);
            });
        }

        this.setState({ continueErr: "" });
        sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
        sessionStorage.setItem("formStage", "two");
        window.location.reload();
      } else {
        this.setState({
          continueErr: "Invalid ClassCode/Description",
          childrenLoc,
        });
        $("#loader").css("display", "none");
        document
          .getElementById(validationCcField)
          .scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  getDomainFromEmail = (user) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let response;
        let generalDomainNames = [
          "gmail",
          "yahoo",
          "aol",
          "mac",
          "cox",
          "frontier",
          "msn",
          "gammana",
          "hotmail",
          "pacbell",
          "att",
          "sbcglobal",
          "windstream",
          "verizon",
          "comcast",
          "ampcocontracting",
        ];
        let tempBusinessName = user.split("@")[1].split(".");
        tempBusinessName.pop();
        let business_name = tempBusinessName.join(".");
        if (generalDomainNames.includes(business_name)) {
          response = user.split("@")[0];
        } else {
          response = business_name;
        }

        let domainResp = await axios.get(awsUrl2 + "/api/getDomain/" + user);
        if (domainResp.data && domainResp.data.domain) {
          response = domainResp.data.domain;
        }

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };

  fetchAndFillDetails = async () => {
    try {
      $("#loader").css("display", "block");
      let user = this.state.companyProfile.emailId.value;

      await axios
        .get(awsUrl + "/api/getUserDataStatus/" + user + "/quote")
        .then(async (response) => {
          if (response.data) {
            let carrierBasedData = response.data.carrierBasedData;
            let currProspect = {},
              sortKeyList = [],
              quoteData = { uuid: "", date: "" },
              address = [];
            for (let carrier in carrierBasedData) {
              for (let line in carrierBasedData[carrier]) {
                let lineObj = carrierBasedData[carrier][line];
                let childrenLoc = {};

                let uwQues = lineObj.uw_ques;

                let companyProfile = {
                  companyName: { value: lineObj.company_name },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  fein: { value: lineObj.fein },
                  emailId: { value: lineObj.user_email_id },
                  phoneNumber: { value: lineObj.phone_number },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  effectiveDate: {
                    value: lineObj.effective_date,
                  },
                  healthQues: {
                    value:
                      lineObj.healthQues === true ||
                      lineObj.healthQues === false
                        ? lineObj.healthQues
                        : true,
                  },
                };

                let emodStatesData = lineObj.emodStatesData;

                sortKeyList.push(lineObj.uuid_carrier);
                quoteData.date = lineObj.date;
                quoteData.uuid = lineObj.uuid_carrier;
                address.push(lineObj.uuid_carrier.split("+")[1]);

                for (let prospectAddressesIndx in lineObj.prospect_addresses) {
                  let prospectAddressesObj =
                    lineObj.prospect_addresses[prospectAddressesIndx];

                  let rand = Math.random();
                  childrenLoc[prospectAddressesIndx] = {
                    cityName: {},
                    classCodesInfo: {},
                    key: rand,
                    number: prospectAddressesObj.addressId,
                    state: { value: prospectAddressesObj.stateCode },
                    zipcode: {},
                  };
                  for (let classCodesIndx in prospectAddressesObj.classCodes) {
                    var formatter = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    });
                    let classCodesObj =
                      prospectAddressesObj.classCodes[classCodesIndx];
                    childrenLoc[prospectAddressesIndx]["classCodesInfo"][
                      classCodesIndx
                    ] = {
                      classCodeDescription: {
                        value: `${classCodesObj.code}: ${classCodesObj.description}`,
                      },
                      ft: { value: classCodesObj.ft },
                      pt: { value: classCodesObj.pt },
                      payroll: {
                        value: formatter.format(classCodesObj.payroll),
                      },
                    };
                  }
                }
                currProspect = {
                  childrenLoc,
                  companyProfile,
                  uwQues,
                  emodStatesData,
                };
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                sessionStorage.setItem(
                  "childrenLoc",
                  JSON.stringify(childrenLoc)
                );
                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
                sessionStorage.setItem("address", JSON.stringify(address));
                sessionStorage.setItem("formStage", "three");
              }
            }
            $("#loader").css("display", "none");
            window.location.reload();
          }
          sessionStorage.setItem("otpVerified", "true");
        })
        .catch((error) => {
          console.log("error in data retrieval");
          $("#loader").css("display", "none");
        });
    } catch (error) {
      console.log("error in GET button click");
    }
  };

  updateHealthDropDown(event) {
    let { companyProfile } = this.state;
    companyProfile.healthQues.value =
      event.target.value === "true" ? true : false;
    this.setState({ companyProfile });
  }

  handleSameAsPrimaryCheck = () => {
    let { sameAsPrimary, companyProfile } = this.state;
    if (sameAsPrimary) {
      companyProfile.cityName2.value = "";
      companyProfile.state2.value = "";
      companyProfile.zipCode2.value = "";
      companyProfile.street2.value = "";
      this.setState({ sameAsPrimary: false, companyProfile });
    } else {
      companyProfile.cityName2.value = companyProfile.cityName1.value;
      companyProfile.state2.value = companyProfile.state1.value;
      companyProfile.zipCode2.value = companyProfile.zipCode1.value;
      companyProfile.street2.value = companyProfile.street1.value;
      this.setState({ sameAsPrimary: true, companyProfile });
    }
  };

  handleSelectedList = (list) => {
    let { carrierList, peoDetails, emodStatesData, childrenLoc } = this.state;

    carrierList = {
      error: false,
      value: list,
    };

    if (
      peoDetails.selectedPeo !== "insurecomp" &&
      peoDetails.selectedPeo !== "wcn"
    )
      this.refreshEmodDetails(
        peoDetails.selectedPeo === "insurecomp" ||
          peoDetails.selectedPeo === "wcn",
        emodStatesData,
        childrenLoc,
        peoDetails.selectedPeo,
        carrierList
      );

    this.setState({ carrierList });
  };

  onContinuePibitCall = async (fileData) => {
    // Calling pibit API with all the required parameters
    try {
      let PIBIT_URL = pibitUrl;
      let CLIENTID = clientId;

      let selectedWorkFlow = "summaryData";

      let fileContentType = fileData?.file[0]["type"];
      let options = { headers: { "Content-Type": fileContentType } };

      const workFlowMap = {
        summaryData: { pibitWorkFlow: "summary", key: "summaryData" },
        detailedData: { pibitWorkFlow: "detailed", key: "detailedData" },
        both: { pibitWorkFlow: "summary", key: "summaryData" },
      };

      let userData = sessionStorage.getItem("user_id");

      let client_id_internal;

      if (process.env["REACT_APP_MODE"] === "dev") {
        client_id_internal = clientIdInternal;
      } else if (process.env["REACT_APP_MODE"] === "beta") {
        client_id_internal = clientIdInternal;
      } else if (process.env["REACT_APP_MODE"] === "prod") {
        client_id_internal = clientId;
      }

      let postFileData = {
        filename: fileData?.file[0]["name"],
        workFlow: workFlowMap[selectedWorkFlow]["pibitWorkFlow"],
        client_id: CLIENTID,
        userID: userData,
        additional_details: {
          key_to_differ_client: client_id_internal,
        },
      };

      const response = await axios.post(
        PIBIT_URL + "/api/getPresignedUrldata",
        postFileData,
        options
      );

      let { uploadUrl, FormDataTokenFields } = response.data;

      const formData = new FormData();
      for (const [key, value] of Object.entries(FormDataTokenFields)) {
        formData.append(key, value);
      }
      formData.append("file", fileData?.file[0]);

      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (uploadResponse) {
        this.uploadLossRun(userData);
        sessionStorage.setItem("uploadLossRun", true);
      }
    } catch (error) {
      console.error("Error calling PIBIT API:", error?.response?.data?.error);
      if (error?.response?.data) {
        // to be handled - error handling in ui
      }
    }
  };

  uploadLossRun = async (userData) => {
    try {
      let etQuoteDetails = {
        user_email_id: userData,
        lossRunDocumentUploaded: true,
        isZeroLossPick: false,
      };
      CommonApi.userTableData(etQuoteDetails, "update")
        .then((res) => {})
        .catch((err) => {
          console.log("lossRunsUploaded: ", err);
        });
    } catch (error) {
      console.error("Error in updating user table", error);
    }
  };
  render() {
    const {
      companyProfile,
      emodStatesData,
      childrenLoc,
      otpVerified,
      otpMessage,
      otpValue,
      salesLogin,
      showCompanyProfile,
      showAdditionalQuestions,
      showLocationAndPayroll,
      sameAsPrimary,
      requestedPricing,
      continueErr,
      userDetails,
      peoDetails,
      carrierList,
    } = this.state;
    let index = 0;

    let expdate =
      companyProfile?.expectedExpiryDate?.value ||
      moment(companyProfile.expectedStartDate.value).add(1, "year");
    console.log(expdate, "hii");
    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;

    let expstartdate = companyProfile.expectedStartDate.value;

    let _carrierList = [];
    for (let data of carrierPeoProgramList) {
      if (data.devRemoved && process.env.REACT_APP_MODE === "dev") continue;
      if (data.prodRemoved && process.env.REACT_APP_MODE === "prod") continue;
      if (data.disableCarrier) continue;

      if (data.range) {
        let expectedStartDate = moment(expstartdate, "YYYY-MM-DD");
        let includeCarrier = false;
        for (let range of data.range) {
          if (!range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");

            if (fromDate.isSameOrBefore(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (!range.from && range.to) {
            const toDate = moment(range.to, "YYYY-MM-DD");
            if (toDate.isAfter(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");
            const toDate = moment(range.to, "YYYY-MM-DD");

            if (
              expectedStartDate.isSameOrAfter(fromDate) &&
              expectedStartDate.isBefore(toDate)
            ) {
              includeCarrier = true;
              break;
            }
          }
        }
        if (!includeCarrier) continue;
      }

      if (data.peo.toLowerCase() === peoDetails.selectedPeo) {
        let { carrier } = data;

        let displayCarrierName = data.actualCarrierName;
        try {
          if (process.env.REACT_APP_MODE === "beta") {
            if (demoNameMapping?.carriers?.[carrier]) {
              displayCarrierName = demoNameMapping.carriers[carrier];
            }
          }
        } catch (error) {}
        // if (data.peo.toLowerCase().split(" ").join("") === peoDetails.selectedPeo) {
        _carrierList.push({
          label: displayCarrierName,
          value: data.carrier,
        });
      } else if (peoDetails.selectedPeo === "demo" && data.peo === "gms") {
        _carrierList.push({
          label: data.carrier?.toUpperCase(),
          value: data.carrier,
        });
      }
    }

    _carrierList.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });

    if (_carrierList.length > 0)
      _carrierList.unshift({
        label: "Select All",
        value: "select_all",
      });

    // console.log("_carrierList: ", _carrierList);

    //flagPreUnderWriter
    let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
      ? true
      : false;

    let isUnderWriterFlow = sessionStorage.getItem("preUnderwriter") === "true";

    let mailCampaignUserCheck = checkMailCampaignUser();
    const titleText = "Please verify/update below details";
    var isLoggedIn =
      sessionStorage.getItem("isLoggedIn") === undefined ||
      sessionStorage.getItem("isLoggedIn") === null
        ? false
        : sessionStorage.getItem("isLoggedIn");
    let locationCount = 1;

    let viewOnly = sessionStorage.getItem("viewOnly")
      ? JSON.parse(sessionStorage.getItem("viewOnly"))
      : false;

    let peoOptions = [
      <option key={0} value="" disabled hidden>
        Please Select
      </option>,
    ];

    let demoPeoPresent;
    let peoUsed = new Set();

    for (let peoData of userDetails.peoList || []) {
      if (!peoUsed.has(peoData.value.toLowerCase())) {
        peoOptions.push(
          <option key={peoData.value} value={peoData.value}>
            {peoData.label}
          </option>
        );

        peoUsed.add(peoData.value.toLowerCase());
      }
    }

    // if (process.env.REACT_APP_MODE === "dev") {
    // if (!demoPeoPresent) {
    //   peoOptions.push(
    //     <option key="demo" value="demo">
    //       Demo
    //     </option>
    //   );
    // }
    // }

    let allStates1 = [
      <option key={0} value="">
        State
      </option>,
    ];
    for (var item of stateCity) {
      if (companyProfile && item === companyProfile.state1.value) {
        allStates1.push(
          <option key={item.Code} value={item.Code.toLowerCase()} selected>
            {item.Code}
          </option>
        );
      } else {
        allStates1.push(
          <option key={item.Code} value={item.Code.toLowerCase()}>
            {item.Code}
          </option>
        );
      }
    }
    let allStates2 = [
      <option key={0} value="">
        State
      </option>,
    ];
    for (var item of stateCity) {
      if (companyProfile && item === companyProfile.state2.value) {
        allStates2.push(
          <option key={item.Code} value={item.Code.toLowerCase()} selected>
            {item.Code}
          </option>
        );
      } else {
        allStates2.push(
          <option key={item.Code} value={item.Code.toLowerCase()}>
            {item.Code}
          </option>
        );
      }
    }

    try {
      locationCount = Object.keys(childrenLoc).length;
    } catch (e) {}

    return (
      <>
        <div id="compPro" className="container-fluid active per98 p-0">
          <NewProgress />
          <div className="parse-acord mb-5">
            <div className="pink-header m-0 p-0 mb-3 bg-white">
              <div className="font-family-montserrat-bold headings">
                Parse Acord
              </div>
            </div>
            <UploadAcordFile
              continueErr={this.setContinueMsg}
              updateFormFields={this.updateFormFields}
            />
            <UploadLossRun
              onCallPibit={(data) => this.setState({ fileData: data })}
            />
          </div>
          <div id="compProfile">
            <form>
              <div className="pink-header p-0 bg-white align-items-center">
                <div className="font-family-montserrat-bold headings">
                  Company Profile
                </div>
                {/* {showCompanyProfile ? (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (showCompanyProfile)
                      this.setState({ showCompanyProfile: false });
                    else return;
                  }}
                >
                  &#8722;
                </span>
              ) : (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (!showCompanyProfile)
                      this.setState({ showCompanyProfile: true });
                    else return;
                  }}
                >
                  &#43;
                </span>
              )} */}
                <div className="compProHeading">{titleText}</div>
              </div>
              {showCompanyProfile && (
                <div className="company-profile-container">
                  <div className="row">
                    <div
                      className="col-lg-6 col-md-12"
                      id="peo-select-container"
                    >
                      <Form.Group className="form-group">
                        <b className="font-family-montserrat-semi-bold">
                          <label for="peo-select">Distributing partner</label>
                        </b>
                        <input
                          required
                          id="peo-select"
                          name="peo-select"
                          type="text"
                          // onChange={(event) => {
                          //   let _carrierList = this.findCarriers(
                          //     event.target.value,
                          //     expstartdate
                          //   );
                          //   this.refreshEmodDetails(
                          //     event.target.value === "insurecomp" ||
                          //       event.target.value === "wcn",
                          //     emodStatesData,
                          //     childrenLoc,
                          //     event.target.value,
                          //     { error: false, value: _carrierList }
                          //   );
                          //   this.setState({
                          //     peoDetails: {
                          //       selectedPeo: event.target.value,
                          //       error: false,
                          //     },
                          //     carrierList: { error: false, value: _carrierList },
                          //   });
                          // }}
                          disabled
                          value={
                            process.env.REACT_APP_MODE === "beta"
                              ? "PEO 1"
                              : "Innoworks"
                          }
                          style={
                            peoDetails && peoDetails.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          // autoComplete="off"
                        ></input>
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                    <div
                      className="col-lg-6 col-md-12"
                      id="carrier-select-container"
                    >
                      <Form.Group className="form-group">
                        <b className="font-family-montserrat-semi-bold ">
                          <label for="peo-select" className="mandatory">
                            Carrier
                          </label>
                        </b>
                        <ReactMultiSelect
                          listToDisplay={_carrierList}
                          selectedList={carrierList.value || []}
                          handleSelectedListCallback={this.handleSelectedList}
                          style={
                            carrierList.error
                              ? { outline: "none", border: "1px solid red" }
                              : {}
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div
                    className="row companyrow_margin"
                    id="company-profile-row1"
                  >
                    <div className="col-lg-6 col-md-12 grey">
                      <Form.Group className="form-group">
                        <label for="companyName" className="mandatory">
                          <b className="font-family-montserrat-semi-bold">
                            Company Name (Applicant Name)
                          </b>
                        </label>
                        <input
                          value={companyProfile.companyName.value}
                          id="companyName"
                          type="text"
                          style={
                            companyProfile.companyName.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "companyName")
                          }
                          placeholder="Company Name"
                          autoComplete="off"
                          className="rectangle1"
                        />
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <Form.Group className="form-group">
                        <label
                          for="descriptionOfOperations"
                          title="Description of Operations"
                          className="mandatory"
                        >
                          <b className="font-family-montserrat-semi-bold">
                            Description of Operations
                          </b>
                        </label>
                        <input
                          value={companyProfile.descriptionOfOperations.value}
                          onChange={(event) =>
                            this.updateCompanyProfile(
                              event,
                              "descriptionOfOperations"
                            )
                          }
                          style={
                            companyProfile.descriptionOfOperations.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          placeholder="Description of Operations"
                          id="descriptionOfOperations"
                          type="text"
                          // onKeyUp={onlyAlpha}
                          autoComplete="off"
                        />
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row feinrow_margin" id="company-profile-row2">
                    <div className="col-lg-2 col-md-12">
                      <Form.Group className="form-group">
                        <label for="fein" className="mandatory">
                          <b className="font-family-montserrat-semi-bold">
                            FEIN
                          </b>
                        </label>
                        <input
                          placeholder="FEIN"
                          value={
                            companyProfile.fein ? companyProfile.fein.value : ""
                          }
                          onChange={(event) => {
                            this.updateCompanyProfile(event, "fein");
                          }}
                          style={
                            companyProfile.fein && companyProfile.fein.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          id="fein"
                          type="text"
                          autoComplete="off"
                          maxLength={9}
                          onInput={(event) => {
                            event.target.value = event.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*)\./g, "$1");
                          }}
                          onBlur={(event) => {
                            var value = event.target.value.toString().trim();
                            value = value.split("-").join("");
                            var len = value.length,
                              dummystr = "000000000";

                            if (len > 0 && len < 9) {
                              value = dummystr.slice(0, 9 - len) + "" + value;
                              companyProfile.fein.value =
                                value[0] + value[1] + "-" + value.slice(2);
                              companyProfile.fein.errMsg = "";
                            } else if (len > 9) {
                              companyProfile.fein.errMsg =
                                "FEIN needs to be 9 digits";
                              companyProfile.fein.error = true;
                            } else if (len === 0) {
                              companyProfile.fein.value = undefined;
                              companyProfile.fein.errMsg = "";
                            } else {
                              companyProfile.fein.value =
                                value[0] + value[1] + "-" + value.slice(2);
                              companyProfile.fein.errMsg = "";
                            }
                            this.setState({ companyProfile });
                          }}
                        />
                        <p className="errMsg">
                          {companyProfile.fein &&
                          companyProfile.fein.errMsg &&
                          companyProfile.fein.errMsg !== ""
                            ? companyProfile.fein.errMsg
                            : ""}
                        </p>
                      </Form.Group>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <Form.Group className="form-group">
                        <label
                          for="firstName"
                          title="First Name"
                          className="mandatory"
                        >
                          <b className="font-family-montserrat-semi-bold">
                            First Name
                          </b>
                        </label>
                        <input
                          placeholder="First Name"
                          value={companyProfile.firstName.value}
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "firstName")
                          }
                          disabled={flagPreUnderWriter}
                          style={
                            companyProfile.firstName.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          id="firstName"
                          type="text"
                          // onKeyUp={onlyAlpha}
                          autoComplete="off"
                        />
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <Form.Group className="form-group">
                        <label
                          for="lastName"
                          title="Last Name"
                          className="mandatory"
                        >
                          <b className="font-family-montserrat-semi-bold">
                            Last Name
                          </b>
                        </label>
                        <input
                          placeholder="Last Name"
                          value={companyProfile.lastName.value}
                          disabled={flagPreUnderWriter}
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "lastName")
                          }
                          style={
                            companyProfile.lastName.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          id="lastName"
                          type="text"
                          // onKeyUp={onlyAlpha}
                          autoComplete="off"
                        />
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row Phone_margin" id="company-profile-row3">
                    <div
                      id="phone-number"
                      style={{ width: "19%", paddingInline: "15px" }}
                    >
                      <Form.Group className="form-group">
                        <label for="phoneNumber">
                          <b className="font-family-montserrat-semi-bold">
                            Phone Number
                          </b>
                        </label>
                        <input
                          value={
                            companyProfile.phoneNumber
                              ? companyProfile.phoneNumber.value
                              : ""
                          }
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "phoneNumber")
                          }
                          onBlur={(event) => {
                            var value = event.target.value.toString().trim();
                            if (event.target.value === "") {
                              return " ";
                            } else {
                              var numericValue = value.replace(/\D/g, "");
                              companyProfile.phoneNumber.value =
                                "(" +
                                numericValue.slice(0, 3) +
                                ") " +
                                numericValue.slice(3, 6) +
                                "-" +
                                numericValue.slice(6);
                              this.setState({ companyProfile });
                            }
                          }}
                          onInput={(event) => {
                            event.target.value = event.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                          }}
                          disabled={flagPreUnderWriter}
                          style={
                            companyProfile.phoneNumber.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          placeholder="Phone Number"
                          autoComplete="off"
                          id="phoneNumber"
                          // format="(###) ###-####"
                          type="text"
                          maxLength={10}
                        />
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                    <div
                      id="entity-type"
                      style={{ width: "40%", paddingInline: "15px" }}
                    >
                      <Form.Group className="form-group">
                        <label htmlFor="entityType">
                          <b className="font-family-montserrat-semi-bold">
                            Entity Type
                          </b>
                        </label>
                        <select
                          value={companyProfile.entityType.value}
                          id="entityType"
                          type="text"
                          style={
                            companyProfile.entityType.error
                              ? { outline: "none", borderColor: "red" }
                              : { fontSize: "16px" }
                          }
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "entityType")
                          }
                          autoComplete="off"
                          // className="form-control"
                        >
                          <option key={1} value={undefined}>
                            {" "}
                            Please Select{" "}
                          </option>
                          <option key={2} value={"Corporation"}>
                            {" "}
                            Corporation{" "}
                          </option>
                          <option key={3} value={"Joint Venture"}>
                            {" "}
                            Joint Venture{" "}
                          </option>
                          <option key={4} value={"LLC"}>
                            {" "}
                            LLC{" "}
                          </option>
                          <option key={5} value={"Not for Profit 501[c][3]"}>
                            {" "}
                            Not for Profit 501[c][3]{" "}
                          </option>
                          <option key={6} value={"Other"}>
                            {" "}
                            Other{" "}
                          </option>
                          <option key={7} value={"Partnership"}>
                            {" "}
                            Partnership{" "}
                          </option>
                          <option key={8} value={"Sole Proprietor"}>
                            {" "}
                            Sole Proprietor{" "}
                          </option>
                          <option key={9} value={"SubCharter 'S' corp"}>
                            {" "}
                            SubCharter 'S' corp{" "}
                          </option>
                          <option key={10} value={"Trust"}>
                            {" "}
                            Trust{" "}
                          </option>
                        </select>
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                    <div
                      id="years-in-business"
                      style={{ width: "19%", paddingInline: "15px" }}
                    >
                      <Form.Group className="form-group">
                        <label
                          for="yearsInBusiness"
                          title="Years in Business"
                          className="mandatory"
                        >
                          <b className="font-family-montserrat-semi-bold">
                            Years in Business
                          </b>
                        </label>
                        <span id="yearsInBusiness">
                          <NumericFormat
                            value={companyProfile.yearsInBusiness.value}
                            onChange={(event) =>
                              this.updateCompanyProfile(
                                event,
                                "yearsInBusiness"
                              )
                            }
                            style={
                              companyProfile.yearsInBusiness.error
                                ? {
                                    outline: "none",
                                    borderColor: "red",
                                    textAlign: "left",
                                  }
                                : { textAlign: "left" }
                            }
                            autoComplete="off"
                            id="yearsInBusiness"
                            format="####"
                            type="text"
                            onKeyDown={(event) => {
                              if (event.key === "-" || event.key === "−") {
                                event.preventDefault(); // Prevent typing the '-' character
                              }
                            }}
                          />
                        </span>
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                    <div
                      id="expected-start-date"
                      style={{ width: "22%", paddingInline: "15px" }}
                    >
                      <Form.Group className="form-group">
                        <label
                          for="expectedStartDate"
                          title="Expected Start Date"
                          className="mandatory"
                        >
                          <b className="font-family-montserrat-semi-bold">
                            Expected Start Date
                          </b>
                        </label>
                        <span id="expectedStartDate">
                          <DatePicker
                            ref={this.expectedStartDateRef}
                            selected={
                              companyProfile?.expectedStartDate?.value &&
                              moment(
                                companyProfile.expectedStartDate.value
                              ).isValid()
                                ? moment(
                                    companyProfile.expectedStartDate.value
                                  ).toDate()
                                : null
                            }
                            onChange={this.handleDateChangeEff}
                            showYearDropdown
                            showMonthDropdown
                            useShortMonthInDropdown
                            wrapperClassName={
                              companyProfile?.expectedStartDate &&
                              companyProfile?.expectedStartDate?.error
                                ? "datePickerError"
                                : ""
                            }
                          />
                        </span>
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                  </div>
                  {
                    <div className="row Phone_margin">
                      <div
                        id="expected-expiry-date"
                        style={{ width: "23%", paddingInline: "15px" }}
                      >
                        <Form.Group className="form-group">
                          <label
                            for="expectedExpiryDate"
                            title="Expected Expiry Date"
                            className="mandatory"
                          >
                            <b className="font-family-montserrat-semi-bold">
                              Expected Expiry Date
                            </b>
                          </label>
                          <span id="expectedExpiryDate">
                            <DatePicker
                              selected={
                                companyProfile.expectedExpiryDate.value
                                  ? moment(
                                      companyProfile.expectedExpiryDate.value
                                    ).toDate()
                                  : new Date()
                              }
                              onBlur={(e) => {
                                if (
                                  companyProfile.expectedStartDate.value.isAfter(
                                    companyProfile.expectedExpiryDate.value
                                  )
                                ) {
                                  window.alert(
                                    "Expected Expiry Date cannot be before Expected Start Date"
                                  );
                                }
                              }}
                              onChange={(e) => {
                                if (!e) {
                                  // Check if the input is empty
                                  this.setState({
                                    companyProfile: {
                                      ...companyProfile,
                                      expectedExpiryDate: {
                                        value: null, // Set the value to null when the input is empty
                                      },
                                    },
                                  });
                                } else {
                                  this.setState({
                                    companyProfile: {
                                      ...companyProfile,
                                      expectedExpiryDate: {
                                        value: moment(e),
                                      },
                                    },
                                  });
                                }
                              }}
                              value={moment(
                                companyProfile.expectedExpiryDate.value
                              ).format("MM/DD/YYYY")}
                              showYearDropdown
                              showMonthDropdown
                              useShortMonthInDropdown
                            />
                          </span>
                          <p className="errMsg" />
                        </Form.Group>
                      </div>

                      {/* <div className="col-lg-4 col-md-12 grey">
                      <Form.Group className="form-group">
                        <label for="companyName" className="mandatory">
                          <b className="font-family-montserrat-semi-bold">
                            Producer
                          </b>
                        </label>
                        <input
                          value={companyProfile?.producer?.value}
                          id="producer"
                          type="text"
                          style={
                            companyProfile?.producer?.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "producer")
                          }
                          placeholder="Producer"
                          autoComplete="off"
                          className="rectangle1"
                        />
                        <p className="errMsg" />
                      </Form.Group>
                    </div> */}
                    </div>
                  }
                  <div className="row-col-2 mb-0 primary_margin">
                    <Form.Group
                      style={{ marginBottom: "19.95px" }}
                      className="form-group sub_headings"
                    >
                      <b className="font-family-montserrat-semi-bold">
                        Primary Location
                      </b>
                    </Form.Group>
                  </div>
                  <div className="row street_margin">
                    <div
                      id="street-1"
                      style={{ width: "51%", paddingInline: "15px" }}
                    >
                      <Form.Group className="form-group">
                        <label for="street1">
                          <b className="font-family-montserrat-semi-bold">
                            Street Address
                          </b>
                        </label>
                        <input
                          value={companyProfile.street1.value}
                          id="street1"
                          type="text"
                          style={
                            companyProfile.street1.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "street1")
                          }
                          placeholder="Street Address"
                          autoComplete="off"
                          className="rectangle1"
                        />
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                    <div
                      id="zip-1"
                      style={{ width: "11%", paddingInline: "15px" }}
                    >
                      <Form.Group className="form-group">
                        <label
                          for={"zipCode1"}
                          title="Zip Code"
                          className={
                            companyProfile?.state1?.value?.toLowerCase() == "ca"
                              ? "mandatory"
                              : ""
                          }
                        >
                          <b className="font-family-montserrat-semi-bold">
                            Zip
                          </b>
                        </label>
                        <NumericFormat
                          name="zipCode1"
                          id={"zipCode1"}
                          format="#####"
                          type="text"
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "zipCode1")
                          }
                          value={
                            companyProfile ? companyProfile.zipCode1.value : ""
                          }
                          onBlur={(event) =>
                            this.setStateAndCity1(event.target.value)
                          }
                          style={
                            companyProfile && companyProfile.zipCode1.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          placeholder="Zip"
                          autoComplete="off"
                          onKeyDown={(event) => {
                            if (event.key === "-" || event.key === "−") {
                              event.preventDefault(); // Prevent typing the '-' character
                            }
                          }}
                        />
                        <p className="errMsg addressErrs" />
                      </Form.Group>
                    </div>
                    <div
                      id="city-1"
                      style={{ width: "27%", paddingInline: "15px" }}
                    >
                      <Form.Group className="form-group">
                        <label for={"cityName1"} title="City">
                          <b className="font-family-montserrat-semi-bold">
                            City
                          </b>
                        </label>
                        <input
                          id={"cityName1"}
                          name="cityName1"
                          type="text"
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "cityName1")
                          }
                          value={
                            companyProfile ? companyProfile.cityName1.value : ""
                          }
                          style={
                            companyProfile && companyProfile.cityName1.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          placeholder="City Name"
                          autoComplete="off"
                        />
                        <p className="errMsg addressErrs" />
                      </Form.Group>
                    </div>
                    <div
                      style={{ width: "11%", paddingInline: "15px" }}
                      id="state-1"
                    >
                      <Form.Group className="form-group">
                        <label title="State">
                          <b className="font-family-montserrat-semi-bold">
                            State
                          </b>
                        </label>
                        <select
                          id={"state1"}
                          name="state1"
                          type="text"
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "state1")
                          }
                          value={
                            companyProfile &&
                            companyProfile.state1 &&
                            companyProfile.state1.value
                              ? companyProfile.state1.value.toLowerCase()
                              : ""
                          }
                          style={
                            companyProfile && companyProfile.state1.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          autoComplete="off"
                        >
                          {allStates1}
                        </select>
                        <p className="errMsg addressErrs" />
                      </Form.Group>
                    </div>
                    <div className="col-sm-1"></div>
                  </div>
                  <div className="d-flex row-col-2 mailing_margin">
                    <Form.Group
                      style={{ marginBottom: "19.95px" }}
                      className="form-group"
                    >
                      <div className="row">
                        <b
                          className="font-family-montserrat-semi-bold"
                          style={{ paddingInline: "15px" }}
                        >
                          Mailing Address
                        </b>
                        <div className=" d-flex align-items-center">
                          <input
                            className="mr-1"
                            type="checkbox"
                            id="sameAsPrimary-1"
                            name={"sameAsPrimary"}
                            checked={sameAsPrimary}
                            onClick={this.handleSameAsPrimaryCheck}
                          />
                          <label for="sameAsPrimary-1">
                            <small>Same as Physical Address</small>
                          </label>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="row mail_street_margin">
                    <div
                      id="street-2"
                      style={{ width: "51%", paddingInline: "15px" }}
                    >
                      <Form.Group className="form-group">
                        <label for="street2">
                          <b className="font-family-montserrat-semi-bold">
                            Street Address
                          </b>
                        </label>
                        <input
                          value={companyProfile.street2.value}
                          id="street2"
                          type="text"
                          style={
                            companyProfile.street2.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "street2")
                          }
                          placeholder="Street Address"
                          autoComplete="off"
                          className="rectangle1"
                        />
                        <p className="errMsg" />
                      </Form.Group>
                    </div>
                    <div
                      style={{ width: "11%", paddingInline: "15px" }}
                      id="zip-2"
                    >
                      <Form.Group className="form-group">
                        <label
                          for={"zipCode2"}
                          title="Zip Code"
                          className={
                            companyProfile?.state2?.value?.toLowerCase() == "ca"
                              ? "mandatory"
                              : ""
                          }
                        >
                          <b className="font-family-montserrat-semi-bold">
                            Zip
                          </b>
                        </label>
                        <NumericFormat
                          name="zipCode2"
                          id={"zipCode2"}
                          format="#####"
                          type="text"
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "zipCode2")
                          }
                          value={
                            companyProfile ? companyProfile.zipCode2.value : ""
                          }
                          style={
                            companyProfile && companyProfile.zipCode2.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          onBlur={(event) =>
                            this.setStateAndCity2(event.target.value)
                          }
                          placeholder="Zip"
                          autoComplete="off"
                        />
                        <p className="errMsg addressErrs" />
                      </Form.Group>
                    </div>
                    <div
                      id="city-2"
                      style={{ width: "27%", paddingInline: "15px" }}
                    >
                      <Form.Group className="form-group">
                        <label for={"cityName2"} title="City">
                          <b className="font-family-montserrat-semi-bold">
                            City
                          </b>
                        </label>
                        <input
                          id={"cityName2"}
                          name="cityName2"
                          type="text"
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "cityName2")
                          }
                          value={
                            companyProfile ? companyProfile.cityName2.value : ""
                          }
                          style={
                            companyProfile && companyProfile.cityName2.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          placeholder="City Name"
                          autoComplete="off"
                        />
                        <p className="errMsg addressErrs" />
                      </Form.Group>
                    </div>
                    <div
                      style={{ width: "11%", paddingInline: "15px" }}
                      id="state-2"
                    >
                      <Form.Group className="form-group">
                        <label title="State">
                          <b className="font-family-montserrat-semi-bold">
                            State
                          </b>
                        </label>
                        <select
                          id={"state2"}
                          name="state2"
                          type="text"
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "state2")
                          }
                          value={
                            companyProfile &&
                            companyProfile.state2 &&
                            companyProfile.state2.value
                              ? companyProfile.state2.value.toLowerCase()
                              : ""
                          }
                          style={
                            companyProfile && companyProfile.state2.error
                              ? { outline: "none", borderColor: "red" }
                              : {}
                          }
                          autoComplete="off"
                        >
                          {allStates2}
                        </select>
                        <p className="errMsg addressErrs" />
                      </Form.Group>
                    </div>
                    <div className="col-sm-1"></div>
                  </div>
                </div>
              )}
              <div className="row notes-row">
                <Form.Group className="form-group">
                  <div className="notes">
                    <textarea
                      defaultValue={companyProfile?.notes?.value || ""}
                      onBlur={(e) => {
                        companyProfile.notes = {
                          value: e.target.value,
                        };
                        this.setState({ companyProfile: companyProfile });
                      }}
                      rows="4"
                      placeholder="Notes if any"
                      style={{ width: "100%" }}
                    ></textarea>
                  </div>
                </Form.Group>
              </div>
              <div className="pink-header sub_headings locAndPayroll">
                <div className="font-family-montserrat-bold">
                  Locations &amp; Payroll
                </div>
                {/* {showLocationAndPayroll ? (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (showLocationAndPayroll)
                      this.setState({ showLocationAndPayroll: false });
                    else return;
                  }}
                >
                  &#8722;
                </span>
              ) : (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (!showLocationAndPayroll)
                      this.setState({ showLocationAndPayroll: true });
                    else return;
                  }}
                >
                  &#43;
                </span>
              )} */}
              </div>
              {showLocationAndPayroll && (
                <div className="calculator location-payroll-container">
                  <div id="allLocations">
                    {/* <span className="formTitle">
                  <h4 className="company-locations">
                    <b>Exposure Details</b>
                  </h4>
                </span> */}
                    <div id="addressDiv">
                      {Object.values(childrenLoc).map((e) => {
                        index++;
                        return (
                          <>
                            <CompLoc
                              index={index}
                              key={e.key}
                              locationInfo={e}
                              updateLocationInfo={this.updateLocationInfo}
                              setStateAndCity={this.setStateAndCity}
                              removeClassCode={this.removeClassCode}
                              // removeLocation={this.onrmvChild}
                              updateClassCodesInfo={this.updateClassCodesInfo}
                              updateLocationError={this.updateLocationError}
                              resetClassCodeDescriptionData={
                                this.resetClassCodeDescriptionData
                              }
                              effectiveDate={companyProfile.effectiveDate}
                              locationCount={locationCount}
                              deleteLocation={this.deleteAddress}
                            />
                            <hr style={{ marginBottom: "2rem" }}></hr>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="btnInvisible addLoc transpButton p-0"
                      onClick={this.onAddChildLoc}
                    >
                      <img src={require("../../../images/plus4.png")} />{" "}
                      <b>Add location</b>
                    </button>
                  </div>
                  {/* <div className="d-flex" style={{ paddingInline: "15px" }}>
                  <div className="font-family-montserrat-semi-bold d-flex align-items-center">
                    Requested Pricing
                  </div>
                  <input
                    type="radio"
                    id="requested-pricing-radio-yes"
                    name="requested-pricing-radio"
                    checked={requestedPricing.checked}
                    onChange={() => {
                      requestedPricing.checked = true;
                      this.setState({ requestedPricing });
                    }}
                  />
                  <Form.Group>
                    <label for={"adminPercent"} title="Admin%">
                      <b className="font-family-montserrat-semi-bold">Admin%</b>
                    </label>
                    <NumericFormat
                      name="adminPercent"
                      id={"adminPercent"}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      suffix="%"
                      type="text"
                      disabled={!requestedPricing.checked}
                      onChange={(event) => {
                        requestedPricing.adminPercent = event.target.value;
                        this.setState({ requestedPricing });
                      }}
                      style={
                        requestedPricing.checked
                          ? {}
                          : { backgroundColor: "#c2c4c5" }
                      }
                      value={
                        requestedPricing ? requestedPricing.adminPercent : ""
                      }
                      placeholder="0.00%"
                      autoComplete="off"
                    />
                  </Form.Group>
                  <input
                    type="radio"
                    id="requested-pricing-radio-no"
                    name="requested-pricing-radio"
                    checked={!requestedPricing.checked}
                    onChange={() => {
                      requestedPricing.checked = false;
                      this.setState({ requestedPricing });
                    }}
                  />
                  <Form.Group>
                    <label for={"perCKdollar"} title="Per CK $">
                      <b className="font-family-montserrat-semi-bold">
                        Per CK $
                      </b>
                    </label>
                    <NumericFormat
                      name="perCKdollar"
                      id={"perCKdollar"}
                      type="text"
                      disabled={requestedPricing.checked}
                      onChange={(event) => {
                        requestedPricing.perCKdollar = event.target.value;
                        this.setState({ requestedPricing });
                      }}
                      style={
                        requestedPricing.checked
                          ? { backgroundColor: "#c2c4c5" }
                          : {}
                      }
                      value={
                        requestedPricing ? requestedPricing.perCKdollar : ""
                      }
                      thousandSeparator={true}
                      prefix={"$"}
                      placeholder="$ 0,0"
                      autoComplete="off"
                    />
                  </Form.Group>
                </div> */}
                </div>
              )}
              {/* {!isUnderWriterFlow && (
              <div className="pink-header">
                <div className="font-family-montserrat-bold">
                  Additional Questions
                </div>
                {showAdditionalQuestions ? (
                  <span
                    className="pink-sign noselect"
                    onClick={() => {
                      if (showAdditionalQuestions)
                        this.setState({ showAdditionalQuestions: false });
                      else return;
                    }}
                  >
                    &#8722;
                  </span>
                ) : (
                  <span
                    className="pink-sign noselect"
                    onClick={() => {
                      if (!showAdditionalQuestions)
                        this.setState({ showAdditionalQuestions: true });
                      else return;
                    }}
                  >
                    &#43;
                  </span>
                )}
              </div>
            )}
            {!isUnderWriterFlow && showAdditionalQuestions && (
              <div className="table-responsive question-list-container">
                <Allques
                  quesList={this.state.companyProfileAddQuestions}
                  updateQuesList={this.updateQuesList}
                  header={false}
                />
              </div>
            )} */}

              <div className="emod-ques">
                {Object.values(emodStatesData).length > 0 && (
                  <div className="pink-header p-0 bg-white align-items-center">
                    <div className="font-family-montserrat-bold headings">
                      Experience Modification
                    </div>
                  </div>
                )}
                {Object.keys(emodStatesData).map((e) => {
                  return (
                    <EmodQuestions
                      key={e}
                      state={e}
                      emodData={emodStatesData[e]}
                      updateEmodInfo={this.updateEmodInfo}
                      expectedStartDate={companyProfile.expectedStartDate}
                    />
                  );
                })}
              </div>
            </form>
            <div className="row pt-5">
              <div className="col-12 d-flex justify-content-center">
                <button
                  type="button"
                  onClick={() => {
                    if (!hideButtons) this.submitCompPro();
                  }}
                  id="profileSaveButton"
                  className="btn mt-0 btn-warning btn-lg btn-warning-shadow btnSubmits "
                  disabled={hideButtons}
                  style={{ width: "auto" }}
                >
                  Continue
                </button>
              </div>
              <div className="col-12 d-flex justify-content-center m-2">
                {continueErr && <p className="errMsg">{continueErr}</p>}
              </div>
            </div>
          </div>
        </div>
        {/* <Toaster /> */}
        {this.state.popupData.show && (
          <Popup
            customStyles={{
              left: "40%",
            }}
            popupData={this.state.popupData}
            updatePopUpData={this.updatePopUpData}
          />
        )}
      </>
    );
  }

  onAddChildLoc = (data) => {
    let { childrenLoc } = this.state;
    let dupChildrenLoc = childrenLoc;
    let rand = Math.random();
    const keys = Object.keys(dupChildrenLoc);
    const index = parseInt(keys[keys.length - 1]) + 1;
    if (!data.location_details) {
      data.location_details = {};
    }
    dupChildrenLoc[index] = {
      number: index,
      key: rand,
      classCodesInfo: { 0: {} },
      state: {},
      zipCode: {},
      cityName: {},
      street: {},
      suta: {},
    };
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
  };

  onrmvChild = (addressId) => {
    let { childrenLoc, emodStatesData, state_class_code } = this.state;

    if (!childrenLoc[addressId].state || !childrenLoc[addressId].state.value) {
      delete childrenLoc[addressId];
      this.setState({ childrenLoc });
      return;
    }

    let stateDeleted = childrenLoc[addressId].state.value;
    let stateDoesNotExistAgain = true;
    let firstToBeDisabledIndex;

    //cc_delete
    for (let classCodeIndex in childrenLoc[addressId].classCodesInfo) {
      let locationIndex = addressId;
      let currState = childrenLoc[locationIndex].state.value || "";
      if (
        currState.toLowerCase() in state_class_code &&
        state_class_code[currState.toLowerCase()].val
      ) {
        let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
          classCodeIndex
        ]["classCodeDescription"]
          ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
              "classCodeDescription"
            ].value
          : undefined;
        if (class_code_desc) {
          let class_code = class_code_desc.split(":")[0].trim();
          if (
            state_class_code[currState.toLowerCase()].cc_dsc &&
            class_code in state_class_code[currState.toLowerCase()].cc_dsc
          ) {
            let locToUpdate, ccToUpdate;
            for (let locIndx in childrenLoc) {
              if (childrenLoc[locIndx].state.value === currState) {
                let ccInfo = childrenLoc[locIndx].classCodesInfo;
                for (let ccIndx in ccInfo) {
                  let cc_desc;
                  try {
                    cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                  } catch (e) {}
                  if (cc_desc) {
                    let cc = cc_desc.split(":")[0].trim();
                    if (Number(cc) === Number(class_code)) {
                      childrenLoc[locIndx].classCodesInfo[ccIndx]["pricing"] = {
                        value:
                          state_class_code[currState.toLowerCase()].cc_dsc[
                            class_code
                          ],
                        disabled: true,
                      };
                      if (
                        !locToUpdate &&
                        !ccToUpdate &&
                        Number(locIndx) !== Number(locationIndex)
                      ) {
                        locToUpdate = locIndx;
                        ccToUpdate = ccIndx;
                      }
                    }
                  }
                }
              }
            }
            if (locToUpdate && ccToUpdate) {
              childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                "pricing"
              ].disabled = false;
              if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
                state_class_code[currState.toLowerCase()].cc_dsc = {};
              }
              try {
                state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                  childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                    "pricing"
                  ].value;
              } catch (error) {}
            } else {
              delete state_class_code[currState.toLowerCase()].cc_dsc[
                class_code
              ];
            }
          }
        }
      }
    }
    //cc_delete

    delete childrenLoc[addressId];

    let stateList = [];
    for (let addressBlockNo in childrenLoc) {
      if (childrenLoc[addressBlockNo].state.value) {
        if (childrenLoc[addressBlockNo].state.value === stateDeleted) {
          childrenLoc[addressBlockNo].suta.disabled = true;
          if (!firstToBeDisabledIndex) firstToBeDisabledIndex = addressBlockNo;
          stateDoesNotExistAgain = false;
        }
        stateList.push(childrenLoc[addressBlockNo].state.value.toLowerCase());
      }
    }

    // if (stateDoesNotExistAgain) {
    //   state_class_code[stateDeleted.toLowerCase()].val = "";
    // }
    if (firstToBeDisabledIndex) {
      childrenLoc[firstToBeDisabledIndex].suta.disabled = false;
    }

    if (stateList.length > 0) {
      let statesData = {
        ncciStates: [],
        actingNcciStates: [],
        indBureauStates: [],
      };

      let carrierPeoProgramList = carrierListData.carrierPeoProgramList;

      let programList = [];

      for (let carrierPeoProgramObj of carrierPeoProgramList) {
        programList.push({ display_name: carrierPeoProgramObj.program });
      }

      axios
        .post(
          awsUrl2 + "/api/raterMcpStateData",
          JSON.stringify({ stateList, programList })
        )
        .then((response) => {
          if (response?.data?.length > 0) {
            for (let state of response.data) {
              if (!(state in emodStatesData)) {
                emodStatesData[state] = {
                  rate1: {
                    value: "",
                  },
                  response: false,
                  displayMore: false,
                  date1: { value: moment() },
                  date2: { value: moment() },
                };
              }
            }

            for (let state in emodStatesData) {
              if (!stateList.includes(state)) {
                delete emodStatesData[state];
              }
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.setState({
            childrenLoc,
            emodStatesData,
            state_class_code,
          });
        });
    } else {
      emodStatesData = {};
      this.setState({ childrenLoc, emodStatesData, state_class_code });
    }
  };
}

export default FormCompPro;
