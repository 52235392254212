import React, { useState, useEffect, useContext } from "react";
import SmallLoader from "../../component/common/SmallLoader";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./UploadLossRun.css";
import moment from "moment";
const uuidv4 = require("uuid/v4");
const JSZip = require("jszip");

const UploadLossRun = ({ onCallPibit }) => {
  const [parseError, setParseError] = useState(false);
  const [parseMessage, setParseMessage] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [uploadingFile, setuploadingFile] = useState(false);
  const [fileData, setFileData] = useState({});
  console.log("🚀 ~ UploadLossRun ~ fileData:", fileData);

  let lossRunUploaded = sessionStorage.getItem("uploadLossRun");

  const [show, setShow] = useState(false);
  const [lossfileUploaded, setLossFileUploaded] = useState(false);

  const handleClose = () => setShow(false);

  const handleConfirm = async () => {
    try {
      onCallPibit(fileData);
      setShow(false);
      setLossFileUploaded(true);
    } catch (error) {
      console.error("Error calling PIBIT API:", error);
      setParseError(true);
      if (error) {
        setParseMessage(error.message);
      }
    }
  };

  function handleFileUpload(event) {
    const files = Array.from(event.target.files); // Convert FileList to Array
    const allowedTypes = [
      "application/pdf",
      "application/zip",
      "application/x-zip-compressed",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];

    // Check if all files are of allowed types
    const allFilesValid = files.every((file) =>
      allowedTypes.includes(file.type)
    );

    if (!allFilesValid) {
      setParseError(true);
      setParseMessage(
        "Wrong format selected. Please select PDF, XLS, XLSX, ZIP, or CSV files."
      );
      return;
    }

    const file = files?.[0];
    if (
      file?.type === "application/zip" ||
      file?.type === "application/x-zip-compressed"
    ) {
      const zip = new JSZip();

      zip.loadAsync(file).then((zipContent) => {
        const entries = Object.keys(zipContent.files);

        if (entries[0].includes(".zip")) {
          console;
          setParseError(true);
          setParseMessage(
            "The uploaded zip can have only one level of zipping."
          );
          return;
        }

        let file_folder_map = {};

        /**
         * [
         *  "abcd.pdf", "hjegfhjgfj.pdf", "folder1/file.pdf", "folder1/file.pdf", "folder1/folder2/file.pdf"
         * ]
         */

        (() => {
          for (let _entry of entries) {
            const pathParts = _entry.split("/"); // Filter out empty parts

            if (pathParts.length > 1) {
              // If there's more than one part, this indicates a folder structure

              if (!file_folder_map[pathParts[0]])
                file_folder_map[pathParts[0]] = {
                  values: [],
                };
              file_folder_map[pathParts[0]].type = "folder";

              if (pathParts[1])
                file_folder_map[pathParts[0]].values.push(
                  pathParts.length > 2 ? "folder" : "file"
                );
            } else {
              if (!file_folder_map[_entry]) file_folder_map[_entry] = {};
              file_folder_map[_entry].type = "file";
            }
          }
        })();

        const hasAllFiles = () => {
          let bool = true;

          for (let _name in file_folder_map) {
            if (file_folder_map[_name].type !== "file") {
              bool = false;
            }
          }

          return bool;
        };

        const folderHasOnlyFiles = (vals) => {
          if (vals.length === 0) return false;
          for (let val of vals) {
            if (val !== "file") return false;
          }
          return true;
        };

        if (
          hasAllFiles() ||
          (Object.keys(file_folder_map).length === 1 &&
            !hasAllFiles() &&
            folderHasOnlyFiles(Object.values(file_folder_map)[0].values))
        ) {
          setFileData({ file: files });
          setParseError(false);
        } else {
          setParseError(true);
          setParseMessage(
            "Zip folder can contain either a single folder or multiple files, but not both."
          );
          return;
        }
      });
    } else {
      // Handle other file types (PDFs, etc.)
      setFileData({ file: event.target.files });
      setParseError(false);
    }
  }

  function uploadFile() {
    if (!fileData?.file) {
      setParseError(true);
      setParseMessage("Please Upload file to proceed");
      return;
    }
    setShow(true);
  }

  function handleDragEnter(event) {
    event.preventDefault();
    if (
      event?.dataTransfer?.items[0]?.type !== "application/zip" &&
      event?.dataTransfer?.items[0]?.type !== "application/pdf" &&
      event?.dataTransfer?.items[0]?.type !== "application/x-zip-compressed" &&
      event?.dataTransfer?.items[0]?.type !== "text/csv" &&
      event?.dataTransfer?.items[0]?.type !== "application/vnd.ms-excel" &&
      event?.dataTransfer?.items[0]?.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setParseError(true);
      setParseMessage(
        "Wrong format selected. Please select PDF, XLS, XLSX, ZIP, or CSV files."
      );
      return;
    }
  }

  let mobile_width = window.innerWidth <= 500 ? { width: "220px" } : {};

  return (
    <>
      <div className="acordParseform">
        {!lossRunUploaded ? (
          <p>
            Upload your <b>Loss runs</b> documents to get price indication
            Please upload Loss value not greater than 90 days.
          </p>
        ) : (
          <span style={{ marginTop: "1rem" }}>
            Loss runs documents already uploaded !
          </span>
        )}

        <form className="">
          <div className="d-flex">
            {!lossRunUploaded && (
              <>
                <div className="form-group">
                  <input
                    label="upload file"
                    type="file"
                    onChange={handleFileUpload}
                    onDrop={handleDragEnter}
                    className="acord_input"
                    style={mobile_width}
                    accept=".zip,.pdf,.xlsx,.xls,.csv"
                    multiple
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary acordSubmitButton"
                    onClick={uploadFile}
                    disabled={parseError || lossfileUploaded}
                  >
                    Submit
                  </button>
                </div>
                {!lossfileUploaded && (
                  <div className="button-container">
                    <span
                      className="info-icon"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="my-icon-class"
                      />
                    </span>
                  </div>
                )}
                {isHovered && (
                  <p>
                    Kindly upload .pdf, .zip, .csv, .xls and .xlsx file format
                    only
                  </p>
                )}
              </>
            )}
          </div>
        </form>
        <div className="">
          {uploadingFile && (
            <span>
              Uploading file <SmallLoader />
            </span>
          )}
          {parseError && (
            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {parseMessage}
            </div>
          )}
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
          <Modal.Body
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Cannot change loss runs once uploaded
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#a33336", color: "white" }}
              onClick={handleConfirm}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default UploadLossRun;
