import styles from "./Modifiers.module.scss";
import { NumericFormat } from "react-number-format";
import { createModifiersTable, getCarrierOptions } from "./utils/utils";
import BrokerDetials from "./utils/BrokerDetails";
const addIcon = require("../../../../images/plus4.png");

function Modifiers({
  data,
  carrierList,
  updateSelectedCarrier: updateSelectedCarrierContext,
  selectedCarrier,
  overridesMap,
  updateOverridesMap,
  setOverridesMap,
}) {
  const { stateTableData } = data || {};

  const updateSelectedCarrier = (carrier) => {
    try {
      updateSelectedCarrierContext(carrier);
      setOverridesMap({ ...overridesMap, selectedCarrier: carrier });
    } catch (error) {
      console.log(error);
    }
  };

  const updateOverridesType = ({ key, type }) => {
    try {
      let _overridesMap = JSON.parse(JSON.stringify(overridesMap));
      _overridesMap.carrierIndependent.overridesType[key] = type;
      setOverridesMap(_overridesMap);
    } catch (error) {
      console.log(error);
    }
  };

  const modifyBrokerList = ({ operation, index, key, value }) => {
    try {
      let _overridesMap = JSON.parse(JSON.stringify(overridesMap));
      let { brokerList } =
        _overridesMap.carrierDependent[selectedCarrier].overallModifiers;

      if (operation === "add" && brokerList[brokerList.length - 1]?.name || brokerList.length === 0) {
        brokerList.push({
          name: "",
          value: null,
          type: "payroll",
        });
      } else if (operation === "remove") {
        brokerList.splice(index, 1);
      } else if (operation === "update") {
        // update the rate to null when changing the broker so that the default value gets picked up
        if (key === "name") {
          brokerList[index].value = null;
        }

        // handle undefined values
        if (!value) {
          if (key === "value") {
            value = 0;
          } else {
            value = "";
          }
        }

        brokerList[index][key] = value;
      }

      setOverridesMap(_overridesMap);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className={styles.modifiersSection}>
      <div className="pink-header p-0 bg-white align-items-center">
        <div className="font-family-montserrat-bold headings">Modifiers</div>
      </div>
      <div className={`sectionContent ${styles.sectionContent}`}>
        <div className={styles.sutaAdminModifiersSection}>
          <h5 className={styles.blockHeader}>SUTA and Admin Modifier</h5>
          <div className={styles.sutaAdminModifiersContainer}>
            <div className={styles.overallRow1}>
              <div className={`${styles.blockContent} w-100`}>
                <div>Admin %</div>
                <NumericFormat
                  value={
                    overridesMap?.carrierIndependent?.overallModifiers?.admin
                  }
                  style={
                    overridesMap?.carrierIndependent?.overridesType?.admin ===
                      "state"
                      ? {
                        backgroundColor: "lightgrey",
                        border: "1px solid grey",
                      }
                      : {}
                  }
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  suffix="%"
                  onClick={() => {
                    updateOverridesType({
                      key: "admin",
                      type: "overall",
                    });
                  }}
                  onValueChange={(values, sourceInfo) => {
                    updateOverridesMap({
                      key: "admin",
                      category: "overallModifiers",
                      value: values.floatValue,
                    });
                  }}
                  placeholder="0.00%"
                />
              </div>
              <div className={`${styles.blockContent} w-100`}>
                <div>SUTA Markup %</div>
                <NumericFormat
                  value={
                    overridesMap?.carrierIndependent?.overallModifiers
                      ?.sutaMarkup
                  }
                  style={
                    overridesMap?.carrierIndependent?.overridesType
                      ?.sutaMarkup === "state"
                      ? {
                        backgroundColor: "lightgrey",
                        border: "1px solid grey",
                      }
                      : {}
                  }
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  suffix="%"
                  onClick={() => {
                    updateOverridesType({
                      type: "overall",
                      key: "sutaMarkup",
                    });
                  }}
                  onValueChange={(values, sourceInfo) => {
                    updateOverridesMap({
                      key: "sutaMarkup",
                      category: "overallModifiers",
                      value: values.floatValue,
                    });
                  }}
                  placeholder="0.00%"
                />
              </div>
            </div>
            <div className={styles.overallRow2}>
              <div className={`${styles.blockContent} w-100`}>
                <div>Commission %</div>
                <label class="checkbox-inline">
                  <input
                    type="checkbox"
                    value="yes"
                    disabled={!selectedCarrier}
                    checked={
                      overridesMap?.carrierDependent?.[selectedCarrier]
                        ?.overallModifiers?.commission === "yes"
                    }
                    onChange={(event) =>
                      updateOverridesMap({
                        carrier: selectedCarrier,
                        key: "commission",
                        category: "overallModifiers",
                        value: event.target.value,
                      })
                    }
                  />
                  Yes
                </label>
                <label class="checkbox-inline">
                  <input
                    type="checkbox"
                    value="no"
                    disabled={!selectedCarrier}
                    checked={
                      overridesMap?.carrierDependent?.[selectedCarrier]
                        ?.overallModifiers?.commission === "no"
                    }
                    onChange={(event) =>
                      updateOverridesMap({
                        carrier: selectedCarrier,
                        key: "commission",
                        category: "overallModifiers",
                        value: event.target.value,
                      })
                    }
                  />
                  No
                </label>
              </div>
            </div>
            {overridesMap?.carrierDependent?.[selectedCarrier]?.overallModifiers
              ?.commission === "yes" && (
                <div className={styles.overallRow3}>
                  {overridesMap?.carrierDependent?.[
                    selectedCarrier
                  ]?.overallModifiers?.brokerList?.map((brokerData, index) => {
                    return (
                      <BrokerDetials
                        modifyBrokerList={modifyBrokerList}
                        key={index}
                        brokerModifiersData={brokerData}
                        allBrokerList={data?.brokerList.sort((a,b) => a.name.localeCompare(b.name))}
                        styles={styles}
                        index={index}
                      />
                    );
                  })}
                  <div
                    className={styles.addBrokerSection}
                    onClick={() => modifyBrokerList({ operation: "add" })}
                  >
                    <img src={addIcon} alt="add broker" />
                    Add broker
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className={styles.stateTableModifierSection}>
          <div className={`${styles.modifiersTableContainer} tableContainer`}>
            {createModifiersTable(
              stateTableData,
              selectedCarrier,
              overridesMap,
              updateOverridesMap,
              updateOverridesType,
              styles
            )}
          </div>
        </div>
        <div className={styles.wcModifiersSection}>
          <h5 className={styles.blockHeader}>WC Modifier</h5>
          <div className={styles.wcModifiersContainer}>
            <div className={styles.blockContent}>
              <div>Carrier</div>
              <select
                name="carriers"
                id="carriers"
                className={styles.carrierValue}
                onChange={(event) => {
                  updateSelectedCarrier(event.target.value);
                }}
              >
                {getCarrierOptions(carrierList, selectedCarrier)}
              </select>
            </div>
            <div className={styles.blockContent}>
              <div>Discount %</div>
              <NumericFormat
                value={
                  overridesMap?.carrierDependent?.[selectedCarrier]
                    ?.overallModifiers?.discount || ""
                }
                disabled={!selectedCarrier}
                decimalScale={2}
                fixedDecimalScale
                suffix="%"
                onValueChange={(values, sourceInfo) => {
                  updateOverridesMap({
                    carrier: selectedCarrier,
                    key: "discount",
                    category: "overallModifiers",
                    value: values.floatValue,
                  });
                }}
                placeholder="0.00%"
              />
            </div>
            <div className={styles.blockContent}>
              <div>Deductible Discount %</div>
              <NumericFormat
                value={
                  overridesMap?.carrierDependent?.[selectedCarrier]
                    ?.overallModifiers?.deductibleDiscount || ""
                }
                disabled={!selectedCarrier}
                decimalScale={2}
                fixedDecimalScale
                suffix="%"
                onValueChange={(values, sourceInfo) => {
                  updateOverridesMap({
                    carrier: selectedCarrier,
                    key: "deductibleDiscount",
                    category: "overallModifiers",
                    value: values.floatValue,
                  });
                }}
                placeholder="0.00%"
              />
            </div>
            <div className={styles.blockContent}>
              <div>Agent Upsell %</div>
              <NumericFormat
                value={
                  overridesMap?.carrierDependent?.[selectedCarrier]
                    ?.overallModifiers?.agentUpsell || ""
                }
                disabled={!selectedCarrier}
                decimalScale={2}
                fixedDecimalScale
                allowNegative={false}
                suffix="%"
                onValueChange={(values, sourceInfo) => {
                  updateOverridesMap({
                    carrier: selectedCarrier,
                    key: "agentUpsell",
                    category: "overallModifiers",
                    value: values.floatValue,
                  });
                }}
                placeholder="0.00%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Modifiers;
