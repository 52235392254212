import React, { Component } from "react";
import axios from "axios";
import { awsAcordUpload } from "../../config";
import SmallLoader from "./smallLoader";
import moment from "moment";
import { quesList } from "../../utils/ques_list";

class UploadFormAcord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingFile: false,
      awaitingFile: false,
      parseError: false,
      parseMesssage: "",
      urlSuccess: false,
      signedUrl: "",
    };
  }

  createFormFieldData = (data) => {
    // remove the default responses from the first 24 acord questions
    let uw_questions = quesList;
    try {
      for (let i = 0; i < 24; i++) {
        delete uw_questions[i].response;
      }
    } catch (error) {}

    try {
      let street2, state2, zipCode2, city2;
      try {
        if (data?.basic_information?.mailing_address?.location_details) {
          street2 =
            data.basic_information.mailing_address.location_details?.address ||
            "";
          state2 =
            data.basic_information.mailing_address.location_details?.state ||
            "";
          zipCode2 =
            data.basic_information.mailing_address.location_details?.pincode ||
            "";
          city2 =
            data.basic_information.mailing_address.location_details?.city || "";
        } else if (data?.basic_information?.mailing_address?.address) {
          street2 = data.basic_information.mailing_address.address;
        }
      } catch (error) {}

      let street1, state1, zipCode1, city1;
      try {
        if (data?.basic_information?.primary_address?.location_details) {
          street1 =
            data.basic_information.primary_address.location_details?.address ||
            "";
          state1 =
            data.basic_information.primary_address.location_details?.state ||
            "";
          zipCode1 =
            data.basic_information.primary_address.location_details?.pincode ||
            "";
          city1 =
            data.basic_information.primary_address.location_details?.city || "";
        } else if (data?.basic_information?.primary_address?.address) {
          street1 = data.basic_information.primary_address.address;
        }
      } catch (error) {}

      let companyProfile = {
        lastName: {
          value: data.basic_information.contact_information?.last_name || "",
        },
        expectedStartDate: {
          value: new moment(data.basic_information.eff_date || ""),
        },
        cityName1: { value: city1 || "" },
        zipCode1: { value: zipCode1 || "" },
        cityName2: { value: city2 || "" },
        zipCode2: { value: zipCode2 || "" },
        entityType: { value: "" },
        companyName: { value: data.basic_information.company_name || "" },
        fein: { value: data.basic_information.fein || "" },
        state2: { value: state2 || "" },
        state1: { value: state1 || "" },
        yearsInBusiness: {
          value: data.basic_information.years_in_business || "",
        },
        firstName: {
          value: data.basic_information.contact_information?.first_name || "",
        },
        phoneNumber: {
          value:
            data.basic_information.phone_number ||
            data.basic_information.mobile_number ||
            "",
        },
        descriptionOfOperations: { value: data.basic_information.desc || "" },
        street1: { value: street1 || "" },
        street2: { value: street2 || "" },
        effectiveDate: {
          value: new moment(data.basic_information.eff_date || ""),
        },
      };

      // prepare children loc
      let locationClassCodeCurrentIndex = {};
      let childrenLoc = {};

      for (let ccIndex in data.state_details) {
        let state = data.state_details[ccIndex].location_details?.state;

        let locNumber;
        try {
          locNumber =
            String(Number(data.state_details[ccIndex].LOC) - 1) ||
            Number(ccIndex) + 1;
        } catch (error) {
          locNumber = data.state_details[ccIndex].LOC || Number(ccIndex) + 1;
        }
        let payroll;

        try {
          let remuneration = data.state_details[ccIndex].REMUNERATION;

          let isNumber = !isNaN(
            remuneration?.trim()?.replace("$", "")?.replace(/,/g, "")
          );
          if (isNumber) {
            payroll = remuneration?.trim()?.replace("$", "");
          }
        } catch (error) {
          console.log(error);
        }

        // initialize/update the current index for the class code for this location
        if (!(locNumber in locationClassCodeCurrentIndex)) {
          locationClassCodeCurrentIndex[locNumber] = -1;
        }
        locationClassCodeCurrentIndex[locNumber]++;

        if (!childrenLoc[locNumber]) {
          let cityName = "";
          let street = "";
          try {
            cityName = data.state_details[ccIndex].location_details?.city;
            street = data.state_details[ccIndex].location_details?.address;
          } catch (error) {}
          childrenLoc[locNumber] = {
            number: locNumber,
            zipCode: {
              value:
                data.state_details[ccIndex].location_details?.pincode || "",
            },
            cityName: {
              value: cityName || "",
            },
            street: {
              value: street || "",
            },
            state: {
              value: state || "",
            },
            classCodesInfo: { 0: {} },
          };
        }
        childrenLoc[locNumber].classCodesInfo[
          locationClassCodeCurrentIndex[locNumber]
        ] = {
          classCodeDescription: {
            error: false,
            value:
              data.state_details[ccIndex].CLASS +
                ": " +
                data.state_details[ccIndex].DESCR || " ",
          },
          payroll: {
            value: payroll ? "$" + payroll : "$ ",
          },
          pt: {
            value: data.state_details[ccIndex].PART || "",
          },
          ft: {
            value: data.state_details[ccIndex].FULL || "",
          },
          fromAcord: true,
        };
      }

      let result = {
        companyProfile,
        childrenLoc,
      };

      if (data?.uw_questions?.length) {
        for (let localIndex in data.uw_questions) {
          let question = data.uw_questions[localIndex];
          let response =
            question?.response?.includes("Y") || question?.yes
              ? true
              : question?.response?.includes("N") || question?.no
              ? false
              : undefined;
          if (response !== undefined) {
            uw_questions[localIndex].response = response;
          }
        }
        sessionStorage.setItem("acordUwQues", JSON.stringify(uw_questions));
      }

      // prepare historical claims
      try {
        if (data?.historical_claims) {
          let acordHistoricalClaims = data.historical_claims;
          sessionStorage.setItem(
            "acordHistoricalClaims",
            JSON.stringify(acordHistoricalClaims)
          );
        }
      } catch (error) {}

      console.log(result);
      this.props.updateFormFields(result);
    } catch (error) {
      console.log("error while parsing acord results", error);
    }
  };

  handleLossOcrParse = (data) => {
    this.setState({ awaitingFile: false, disableBind: false });
    if (data.fileType === "success") {
      this.setState({
        parseMesssage: "Parsing complete!",
        parseError: false,
        urlSuccess: true,
        signedUrl: data.url,
      });
      this.createFormFieldData(data.data);
    } else if (data.fileType === "error") {
      this.setState({
        parseMesssage:
          "Unable to process the loss file. Please contact admin@insurecomp.com for further clarification.",
        parseError: true,
      });
    } else {
      this.setState({
        parseMesssage:
          "Unable to generate the download link. Please contact admin@insurecomp.com for further clarification.",
        parseError: true,
      });
    }
  };

  s3Poller = (fileName) => {
    axios
      .get(
        awsAcordUpload +
          `/api/s3PollerJson/ocr-doc-demo-acord/${fileName}/us-east-1`
      )
      .then((result) => {
        if (result.data.fileType) {
          console.log("here in");
          this.handleLossOcrParse(result.data);
        } else {
          this.s3Poller(fileName);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        this.setState({
          parseError: true,
          awaitingFile: false,
          parseMesssage:
            "Unable to process the loss file. Please contact admin@insurecomp.com for further clarification.",
        });
      });
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
    const file = event.target.files[0];
    if (file) {
      // Validate file type
      if (file.type !== "application/pdf") {
        this.setState({
          parseError: true,
          parseMesssage: "Wrong format selected. Select a PDF file.",
        });
        return;
      } else {
        this.setState({
          parseError: false,
        });
      }

      // Process the file upload here
      console.log("File selected:", file);
    }
  };

  uploadFile = (event) => {
    this.props.continueErr();
    // Check if a file is selected
    if (!this.state.file || this.state.file.length === 0) {
      // Set state to indicate error

      this.setState({
        parseError: true,
        parseMesssage: "Please upload a file.",
      });
      return; // Exit the function
    }

    // Reset any previous error state
    this.setState({
      uploadingFile: true,
      parseError: false,
      error: false,
      urlSuccess: false,
      signedUrl: "",
      awaitingFile: false,
    });

    const that = this;
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", this.state.file[0]);
    let mode = process.env.REACT_APP_MODE;
    formData.append("client", "ies");
    formData.append("environment", mode);

    axios
      .post(awsAcordUpload + "/api/fileUploadAcord", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        that.setState({ uploadingFile: false });
        that.setState({ awaitingFile: true });
        const fileName = response.data.fileName;
        const folder = fileName.split(".")[0];
        console.log("file uploaded:", folder);
        this.s3Poller(folder);
      })
      .catch((error) => {
        this.setState({ uploadingFile: false });
        console.log("error", error);
      });
  };

  render() {
    const {
      uploadingFile,
      awaitingFile,
      urlSuccess,
      signedUrl,
      parseError,
      parseMesssage,
    } = this.state;

    let mobile_width = window.innerWidth <= 500 ? { width: "220px" } : {};

    return (
      <div className="acordParseform">
        <p>Select an acord document for analysis</p>
        <form className="">
          <div className="d-flex">
            <div className="form-group">
              <input
                label="upload file"
                type="file"
                onChange={this.handleFileUpload}
                className="acord_input"
                style={mobile_width}
                accept=".pdf"
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary acordSubmitButton"
                onClick={this.uploadFile}
                disabled={parseError}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        <div className="">
          {uploadingFile && (
            <span>
              Uploading file <SmallLoader />
            </span>
          )}
          {awaitingFile && (
            <span>
              Waiting for file creation <SmallLoader />
            </span>
          )}
          {urlSuccess && (
            <div>
              <a href={signedUrl}>
                {parseMesssage} Click here to download your file.
              </a>
            </div>
          )}
          {parseError && (
            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {parseMesssage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UploadFormAcord;
